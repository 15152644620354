import React from 'react'
import {  red_color, secondary_color } from '../../const/color_theam'
import { SecondaryButton } from '../button/Button.style'
import { MediaCardWraper } from '../Card/Card.style'
import { Subtitle } from '../typography/Subtitle.style'
import { Title } from '../typography/Title.style'
import { FlexWrapper, SmallImageWrapper } from '../wrapper/Wrapper.style'

const index = ({ item, selectMedia, size, deleteMedia, handleMediaClick }) => {
  const {mediaName, type } = item
  return (
    <div >
      <MediaCardWraper>
        {
          item.type === 'Image' ?
            <SmallImageWrapper title={mediaName} image={item.thumbnail} onClick={() => { selectMedia(item) }} size={size}  />
            :
            <img title={mediaName} onClick={ () => handleMediaClick(item)} src={require(`../../assets/images/dummy/video-thumnail.png`)} alt="media" style={{ borderRadius: '6px', cursor: 'pointer' }} width={size || '220px'} height={size || '160px'}  />
        }
        <FlexWrapper height="40px" justify="space-between" width='100%' marginTop="8px">
          <FlexWrapper  align='flext-start' justify='flex-start' direction="column" >
            <Title color={secondary_color} size='12px'> {mediaName && mediaName.substring(0, 15).split('-').join(" ")}  </Title>
            <Subtitle color={secondary_color}> {type} </Subtitle>
          </FlexWrapper>
         
          <SecondaryButton color={secondary_color} hovercolor={red_color}  onClick={ () => deleteMedia(item)}>
          <i class="fa-solid fa-trash-can"></i>
          </SecondaryButton>
        </FlexWrapper>
      </MediaCardWraper>

    </div>
  )
}

export default index
