import { primary_color, red_color, theam_black } from '../../const/color_theam'
import { PrimaryButton } from '../button/Button.style'
import { TitleOne } from '../typography/Title.style'
import { Popup, PopupWrapper } from './Popup.style'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import './style.css'
import { Caption } from '../typography/Caption.style'

const Index = ({ onCancel, onConfirm, heading , confirmTitle='Okey', loading = false, currentMedia, handleChange }) => {
  return (
    <>
      <Popup onClick={onCancel} />
      <PopupWrapper >
        <FlexWrapper align='center' justify='center' marginBottom='30px'>
          <TitleOne color={theam_black}  >{heading}</TitleOne>
        </FlexWrapper>

       <Caption marginBottom='8px'>  Image View </Caption>
      <select name='view' value={currentMedia.view} onChange={handleChange} className="custom-dropdown">
        <option value="cover">cover</option>
        <option value="contain">contain</option>
      </select>

     <Caption marginBottom='8px' marginTop='20px'> Duration </Caption>
      <select name='duration' value={currentMedia.duration} onChange={handleChange} className="custom-dropdown">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="60">60</option>
      </select>
        <FlexWrapper align='center' justify='flex-end' marginTop='10px'>
          <PrimaryButton marginTop='20px' marginRight="25px" color='#F5F5FB' fontcolor='#060609' onClick={onCancel} >Cancel</PrimaryButton>
          <PrimaryButton marginTop='20px' marginRight="0px" color={ confirmTitle === 'Delete' ? red_color : primary_color} onClick={onConfirm} >
          { loading ? <div className='loader-small' /> :  confirmTitle}
            </PrimaryButton>
        </FlexWrapper>
      </PopupWrapper>
    </>
  )
}

export default Index