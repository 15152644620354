import React, { useEffect, useRef, useState } from 'react'
import { getScreenMediaList } from '../../apis/screenapi'
import { PlayerWrapper, ImageWrapper } from '../../components/wrapper/Wrapper.style'
import axios from 'axios'

const Index = () => {
  const [media, setMedia] = useState(null)
  const [active, setActive] = useState(null)
  const pointer = useRef(0)

  useEffect(() => {
    axios(getScreenMediaList)
      .then(res => {
        const { media } = res.data
        setMedia(media.sort(() => Math.random() - 0.5))
        setActive(media[5])
      })
      .catch(err => console.log('error', err))
  }, [])

  const playNext = () => {
    pointer.current = pointer.current + 1
    if (pointer.current >= media.length) {
      pointer.current = 0
    }
    setActive(media[pointer.current])
  }

  const start = () => {
    setInterval(() => {
      pointer.current = pointer.current + 1
      if (pointer.current >= media.length) {
        pointer.current = 0
      }
      setActive(media[pointer.current])
    }, [5000])
  }

  window.next = playNext
  window.start = start

  return (
    <>

      {
      !active &&
        <ImageWrapper />
    }

      {
      active && active.type === 'Image' &&
        <ImageWrapper image={active.url} />
    }
      {
      active && active.type === 'Video' &&
        <PlayerWrapper>
          <video id='video' width='100%' height='100%' controls>
            <source src={active.url} type='video/mp4' />
          </video>
        </PlayerWrapper>
    }

    </>

  )
}

export default Index
