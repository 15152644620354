import React, { useEffect, useState } from 'react'
import { MediaGridWrapper, AppWrapper, FlexWrapper } from '../../components/wrapper/Wrapper.style'
import MediaCard from '../../components/MediaCard/index'
import Topbar from '../../components/Topbar/index'
import UploadMediaPopup from '../../components/UploadMediaPopup/index'
import axios from 'axios'
import async from 'async'
import { getUserName } from '../../utils/commonUtils'
import { Upload, ProgressBar, ProgressTrack } from '../../components/UploadStatus/UploadStatus.style'
import Loader from '../../components/Loader/index'
import Popup from '../../components/Popup/index'
import MediaViewer from '../../components/MediaViewer/index'

const Index = () => {
  const [media, setMedia] = useState(null)
  const [selectedMedia, setSelectedMedia] = useState(null)
  const [showMediaUploadPopup, setShowMediaUploadPopup] = useState(false)
  const [deletePopup, setDeletPopup] = useState(false)

  const closeMediaUploadPopup = () => {
    setShowMediaUploadPopup(false)
  }
  const openMediaUploadPopup = () => {
    setShowMediaUploadPopup(true)
  }

  const [mediaName, setMediaName] = useState(null)
  const [uploadProgress, setUploadProgrss] = useState(0)

  const getMediaFiles = () => {
    console.log('getting media fille')
    const getScreenMediaList = {
      url: '/listMedias',
      method: 'POST',
      data: JSON.stringify({ username: getUserName() })
    }

    axios(getScreenMediaList)
      .then(res => {
        console.log('media list api res', res.data)
        const { media } = res.data
        setMedia(media)
      })
      .catch(err => console.log('media list api error', err))
  }

  const deleteMedia = () => {
    const data = [selectedMedia]
    const nonDeltedMedia = media.filter((media) => media.id !== selectedMedia.id)
    setMedia(nonDeltedMedia)
    const deleteMedia = {
      url: '/deleteMedia',
      method: 'POST',
      data: JSON.stringify(data)
    }
    closeDeletePopup()

    axios(deleteMedia)
      .then(res => {
        console.log('media deleted', res.data)
      })
      .catch(err => console.log('media delte failed', err))
  }

  const onDeleteMedia = (item) => {
    setSelectedMedia(item)
    setDeletPopup(true)
  }

  const closeDeletePopup = () => {
    setSelectedMedia(null)
    setDeletPopup(false)
  }

  useEffect(() => {
    getMediaFiles()
  }, [])

  // const handleImage2 = (file) => {
  //   // console.log('file.name', file[0].name)

  //   if (file[0]) {
  //     let percentage

  //     const videoUploaderOptions = {
  //       fileName: 'foo',
  //       file: file[0]
  //     }
  //     const uploader = new Uploader(videoUploaderOptions)
  //     setUploader(uploader)

  //     uploader
  //       .onProgress(({ percentage: newPercentage }) => {
  //         // to avoid the same percentage to be logged twice
  //         if (newPercentage !== percentage) {
  //           percentage = newPercentage
  //           console.log(`${percentage}%`)
  //         }
  //       })
  //       .onError((error) => {
  //         console.error(error)
  //       })

  //     uploader.start()
  //   }
  // }

  const handleImage = (file) => {
    for (let i = 0; i < file.length; i++) {
      const token = localStorage.getItem('token')
      console.log('file[i]....', file[i])
      const pre_upload = {
        name: file[i].name,
        type: file[i].type,
        token: token,
        ContentType: file[i].type
      }

      setMediaName(file[i].name)

      const reader = new FileReader()
      const fd = new FormData()
      fd.append('file', file[i])
      reader.readAsArrayBuffer(file[i])

      const fileSize = file[i].size / (1024 * 1024)

      if (fileSize < 10) {
        axios.post('/getSignedUrl', pre_upload).then(res => {
          const options = {
            headers: {
              'Content-Type': file[i].type
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent
              const percent = Math.floor((loaded * 100) / total)
              console.log(`${loaded}kb of ${total}kb | ${percent}%`)
              setUploadProgrss(percent)
            }
          }
          const upload_file = new Blob([reader.result], { type: file[i].type })
          console.log('file uploading.... ', i)
          closeMediaUploadPopup()
          const fileName = res.data.fileName
          axios.put(res.data.uploadURL, upload_file, options).then(res => {
            console.log('res..........', res)

            const mediaData = {
              mediaName: file[i].name,
              fileName: fileName,
              type: file[i].type === 'video/mp4' ? 'Video' : 'Image',
              duration: 5,
              view: 'cover',
              url: 'https://viewbox-media.s3.amazonaws.com/media/' + fileName,
              username: getUserName()
            }
            const createMedia = {
              url: '/createMedia',
              method: 'POST',
              data: JSON.stringify(mediaData)
            }
            axios(createMedia)
              .then(() => {
                getMediaFiles()
                setTimeout(() => {
                  setUploadProgrss(0)
                  setMediaName(null)
                }, 3000)
              })
              .catch((error) => {
                console.log('create media error', error)
              })
          })
        })
      } else {
        const chunkSize = 1024 * 1024 * 6

        axios.post('/getMultipartSignedUrl', pre_upload).then(res => {
          const numberOfparts = Math.ceil(file[i].size / chunkSize)

          const AWSFileDataOutput = res.data.uploadURL
          const uploadId = AWSFileDataOutput.UploadId
          const fileKey = AWSFileDataOutput.Key
          const type = file[i].type

          const parts = []
          const chunks = []
          let offset = 0
          for (let j = 0; j < numberOfparts; j++) {
            const _parts = {
              partNumber: j + 1
            }
            parts.push(_parts)
            const chunk = file[i].slice(offset, chunkSize + offset)
            const _chunk = {
              [j + 1]: chunk,
              partNumber: j + 1
            }
            offset += chunkSize
            chunks.push(_chunk)
          }
          const AWSMultipartFileDataInput = {
            fileKey, uploadId, type, parts
          }

          axios.post('/getMultipartPreSignedUrls', AWSMultipartFileDataInput).then(res => {
            const uploadUrls = res.data.uploadURLs
            const uploadedParts = []
            async.eachOfSeries(uploadUrls, (uploadURL, index, icb) => {
              console.log('index', index)

              // upload(uploadURL, chunks[index]).then(uploadedPart => {
              //   uploadedParts.push(uploadedPart)
              //   icb(null)
              // })
              console.log(chunks[index].partNumber, uploadURL.partNumber)
              const options = {
                headers: {
                  'Content-Type': file[i].type
                },
                onUploadProgress: () => {
                  const percent = Math.floor((uploadURL.partNumber / numberOfparts) * 100)
                  console.log('percent', percent)
                  setUploadProgrss(percent)
                }
              }
              axios.put(uploadURL.signerUrl, chunks[index][uploadURL.partNumber], options)
                .then(res => {
                  let ETag = res.headers.etag
                  try {
                    ETag = JSON.parse(ETag)
                  } catch (error) {

                  }
                  const uploadedPart = {
                    PartNumber: uploadURL.partNumber,
                    ETag: ETag
                  }
                  uploadedParts.push(uploadedPart)
                  icb(null)
                }).catch(aerr => {
                  icb(aerr)
                })
            }, (err, reso) => {
              if (err) {
                console.log(err)
              } else {
                const sorteduploadedParts = uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber)

                const videoFinalizationMultiPartInput = {
                  fileKey,
                  uploadId,
                  parts: sorteduploadedParts
                }
                axios.post('/finalizeMultipartUpload', videoFinalizationMultiPartInput).then(res => {
                  const mediaData = {
                    mediaName: file[i].name,
                    fileName: fileKey,
                    type: file[i].type === 'video/mp4' ? 'Video' : 'Image',
                    duration: 5,
                    view: 'cover',
                    url: 'https://viewbox-media.s3.amazonaws.com/media/' + fileKey,
                    username: getUserName()
                  }

                  const createMedia = {
                    url: '/createMedia',
                    method: 'POST',
                    data: JSON.stringify(mediaData)
                  }
                  axios(createMedia)
                    .then(() => {
                      getMediaFiles()
                      setTimeout(() => {
                        setUploadProgrss(0)
                        setMediaName(null)
                      }, 3000)
                    })
                    .catch((error) => {
                      console.log('create media error', error)
                    })
                })
              }
            })
          })
          closeMediaUploadPopup()
        })
      }
    }
  }

  // const upload = (part, file) => {
  //   // uploading each part with its pre-signed URL
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest()

  //     // const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)

  //     // xhr.upload.addEventListener('progress', progressListener)

  //     // xhr.addEventListener('error', progressListener)
  //     // xhr.addEventListener('abort', progressListener)
  //     // xhr.addEventListener('loadend', progressListener)

  //     xhr.open('PUT', part.signerUrl)

  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4 && xhr.status === 200) {
  //         const ETag = xhr.getResponseHeader('ETag')

  //         if (ETag) {
  //           const uploadedPart = {
  //             PartNumber: part.partNumber,
  //             ETag: ETag.replaceAll('"', '')
  //           }

  //           // this.uploadedParts.push(uploadedPart)

  //           resolve(uploadedPart)
  //           // delete this.activeConnections[part.PartNumber - 1]
  //         }
  //       }
  //     }

  //     xhr.onerror = (error) => {
  //       reject(error)
  //       // delete this.activeConnections[part.PartNumber - 1]
  //     }

  //     xhr.onabort = () => {
  //       reject(new Error('Upload canceled by user'))
  //       // delete this.activeConnections[part.PartNumber - 1]
  //     }

  //     xhr.send(file)
  //   })
  // }

  // console.log('uploadProgress', uploadProgress)

  let sortedMedia = null
  if (media && media.length > 1) {
    sortedMedia = media.sort(function (x, y) {
      const firstDate = new Date(x.createdDate)
      const SecondDate = new Date(y.createdDate)

      if (firstDate < SecondDate) return 1
      if (firstDate > SecondDate) return -1
      return 0
    })
  }

  const [searchTerm, setSearchTerm] = useState('')
  const searchOnChange = (e) => {
    setSearchTerm(e.target.value)
  }

  if (searchTerm.length > 0) {
    sortedMedia = media && media.filter(item =>
      item.mediaName.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  const [viewMedia, setViewMedia] = useState(false)
  const handleMediaClick = (media) => {
    setViewMedia(true)
    setSelectedMedia(media)

    console.log('media', media)
  }
  const closeMeidaView = () => {
    setViewMedia(false)
    setSelectedMedia(null)
  }
  return (
    <AppWrapper>
      {
        showMediaUploadPopup &&
          <UploadMediaPopup
            closePopup={closeMediaUploadPopup} handleImage={handleImage}
          />
      }

      {
        selectedMedia && deletePopup &&
          <Popup
            closeCreateScreenPopup={closeDeletePopup}
            create={deleteMedia}
            confirmTitle='Delete'
            heading='Delete Media'
            message='Are you sure you want to delete?'
          />
      }

      {
        viewMedia &&
          <MediaViewer
            closePopup={closeMeidaView} handleImage={handleImage} selectedMedia={selectedMedia}
          />
      }

      {
        uploadProgress && mediaName ?
        (
          <Upload>
            <p>Uploading {mediaName}  </p>
            <ProgressTrack>
              <ProgressBar width={`${uploadProgress}px`} />
            </ProgressTrack>
          </Upload>
        ) : null
      }

      <Topbar
        placeholder='Search media' title='Media'
        searchOnChange={searchOnChange}
        buttonOne='Add Media' buttonOneClick={openMediaUploadPopup}
      />
      {
     sortedMedia && sortedMedia.length > 0
       ? <MediaGridWrapper>
         {
          sortedMedia && sortedMedia.map((item, i) => <MediaCard handleMediaClick={handleMediaClick} key={i} item={item} deleteMedia={onDeleteMedia} />)
        }
         </MediaGridWrapper>
       : <FlexWrapper height='500px' width='100%' align='center' justify='center'>
         <Loader />
         </FlexWrapper>
}
    </AppWrapper>
  )
}

export default Index
