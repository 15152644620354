import React, { useContext, useState, useEffect } from 'react'
import { CardGridWrapper, AppWrapper, FlexWrapper, ScrollWrapper } from '../../components/wrapper/Wrapper.style'
import ScreenCard from '../../components/ScreenCard/index'
import Topbar from '../../components/Topbar/index'
import { StyledLink } from '../../components/Link/StyledLink.style'
import { Store } from '../../store/store'
import Popup from '../../components/Popup/index'
import axios from 'axios'
import { addScreen, updateScreenList } from '../../actions/index'
import { getOnlineStatus, getUserName } from '../../utils/commonUtils'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from '../../components/Loader/index'
import { Caption } from '../../components/typography/Caption.style'
import { theam_green, theam_violet } from '../../const/color_theam'

const Index = () => {
  const { state } = useContext(Store)
  const { screens: sc } = state
  const [showCreateScreenPopup, setCreateScreenPopup] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [newScreenData, setNewScreenData] = useState('')
  const { dispatch } = useContext(Store)
  const [createScreenError, setCreateScreenError] = useState(null);
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNewScreenData({ ...newScreenData, [name]: value })
  }

  const openCreateScreenPopup = () => {
    setCreateScreenPopup(true)
  }

  const closeCreateScreenPopup = () => {
    setCreateScreenPopup(false)
  }

  const searchOnChange = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    const listScreens = {
      url: '/listScreens',
      method: 'POST',
      data: JSON.stringify({ username: getUserName() })
    }
    axios(listScreens)
      .then((response) => {
        const { data: { screens } } = response
        console.log('list Screens api resp', screens)
        updateScreenList(screens, dispatch)
      })
      .catch((error) => {
        console.log('list Screens api resp', error)
      })
  }, [dispatch])

  const createNewScreen = () => {
    setCreateScreenError(null)
    if (newScreenData && newScreenData.keyCode && newScreenData.name) {
      const data = {
        key: newScreenData.keyCode.toUpperCase() ,
        username: getUserName(),
        screenName: newScreenData.name
      }

      const linkScreen = {
        url: '/linkScreen',
        method: 'POST',
        data: JSON.stringify(data)
      }

      axios(linkScreen)
        .then((response) => {
          console.log('linkscreen api resp', response)
          const { data } = response
          addScreen(data, dispatch)
          closeCreateScreenPopup()
        })
        .catch((error) => {
          const {data} = error.response
          setCreateScreenError(data)
          console.log('link screen api err', data)
        })
    }
  }

  let screens = sc.sort((a, b) => {
      if (getOnlineStatus(a.screenLiveTime) < getOnlineStatus(b.screenLiveTime)){
         return -1;
      } else if (getOnlineStatus(a.screenLiveTime) > getOnlineStatus(b.screenLiveTime)) {
        return 1
      } else {
          if (getOnlineStatus(a.screenLiveTime) === getOnlineStatus(b.screenLiveTime)) {
            return a.screenName.localeCompare(b.screenName);
          } else {
            return 0;
          }
        } 
  });

  if (searchTerm.length > 0) {
    const searchResult = screens.filter(item =>
      item.screenName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    screens = searchResult
  } else {
    screens = sc
  }

  const getOnlineScreenCount = () => {
    const onlineArr = sc.filter((item) => getOnlineStatus(item.screenLiveTime) === 'Online' )
    return onlineArr.length
  }

  return (
    <AppWrapper>
      {
        showCreateScreenPopup &&
        <Popup
          closeCreateScreenPopup={closeCreateScreenPopup}
          create={createNewScreen}
          handleInputChange={handleInputChange}
          data={newScreenData}
          lablelOne='Screen Name' lableTwo='Key Code'
          nameOne='name'
          nameTwo='keyCode'
          heading='Create Screen'
          error={createScreenError}
        />
      }

      <Topbar placeholder='Search screens' buttonTwo='Create Screen' title='Screens'
        buttonTwoClick={openCreateScreenPopup} searchOnChange={searchOnChange} />
        {
          sc.length > 0 && 
          <FlexWrapper paddingBottom='20px'>
          <Caption margin='4px' color={theam_green}> {getOnlineScreenCount()}  Screens In Online </Caption>  
          <Caption margin='14px' color={theam_violet}> { sc.length - getOnlineScreenCount() }  Screens In Offline </Caption>  
          </FlexWrapper>
        }
      
      {
        screens.length > 0 ?
        <ScrollWrapper>
        <CardGridWrapper paddingTop='2px'>
        {
          screens.map((item, i) =>
            <StyledLink to={`/screens/manage/${item.screenId}/${item.screenName}`} key={i}>
              <ScreenCard screenId={item.screenId} screenLiveTime={item.screenLiveTime} lastOnline={item.lastOnline || null}
                screenName={item.screenName} startTime={item.startTime} content={item.content} status={item.status} />
            </StyledLink>
          )
        }
      </CardGridWrapper>
      </ScrollWrapper>
     :
     <FlexWrapper height="500px" width="100%"  align='center' justify='center' >
     <Loader />
     </FlexWrapper>
      }
    </AppWrapper>
  )
}

export default Index
