export const screenMedia = [
{  
  screenId: '0001' ,  media: [ 
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
 ] },
{  
  screenId: '0002' ,  media: [ 
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
 ] },
{  
  screenId: '0003' ,  media: [ 
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
 ] },
{  
  screenId: '0004' ,  media: [ 
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
 ] },
{  
  screenId: '0005' ,  media: [ 
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
  { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
  { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
  { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
 ] },
]

export const  screens = [ 
  { screenId: '0001' ,screenName: 'Hostel Room' , startTime: 'start at 2 May 09:07AM', content: 'xyz' , status: 'Offline' } ,
  { screenId: '0002' ,screenName: 'HR room' , startTime: 'start at 11 May 09:07AM', content: 'xyz' , status: 'Offline',   } ,
  { screenId: '0003' ,screenName: 'Office' , startTime: 'start at 10 May 02:00AM', content: 'Add abc' , status: 'Playing' ,  } ,
  { screenId: '0004' ,screenName: 'Front office' , startTime: 'start at 05 May 10:00PM', content: 'Movie',  status: 'Playing',  } ,
  { screenId: '0005' ,screenName: 'Town hall' , startTime: 'start at 11 May 09:07AM', content: 'xyz',status: 'Playing',}
 ]

 export const playlist = [ 
  { playlistName: 'Playlist II' , count: 30, duration: '01:10' , numberOfScreens: 8 , media: [ 
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
   ]   } ,
  { playlistName: 'Viewbox demo' , count: 10, duration: '00:10' , numberOfScreens: 1 , media: [ 
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
   ] } ,
  { playlistName: 'Xyz' , count: 15, duration: '00:15' , numberOfScreens: 3, media: [ 
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
   ]  } ,
  { playlistName: 'MYG Kozhikode' , count: 22, duration: '03:00' , numberOfScreens: 13, media: [ 
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
   ]  } ,
  { playlistName: 'Playlist BC' , count:4, duration: '00:02' , numberOfScreens: 1, media: [ 
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
    { mediaName: 'Media xyz' , type: 'Image', duration: '30Sec', view:'cover'  } ,
    { mediaName: 'Abc' , type: 'Image', duration: '45Sec', view:'cover'  } ,
    { mediaName: 'Photo' , type: 'Image', duration: '60sec', view:'cover'  } ,
   ]  } ,
 ]   


 export const userData = {
  username:'',
  plan:'',
  subscription: 'active',
 }


 export const userInfo = [
  { username: 'Mahroof',
    screenlist: [
      {
        screenname: 'screen one',
        isArchived: false,
        screenTime: [
        'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 
        ]
      },
      {
        screenname: 'screen two',
        isArchived: false,
        screenTime: [
        'date', 'date', 'date',
        ]
      }
    ] 
  },
  { username: 'Muqsith',
  screenlist: [
    {
      screenname: 'screen w',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date'
      ]
    },
    {
      screenname: 'screen x',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date'
      ]
    },
    {
      screenname: 'screen y',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date','date', 'date', 'date', 'date', 'date', 'date','date', 'date', 'date', 'date', 'date', 'date'
      ]
    },
    {
      screenname: 'screen z',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date'
      ]
    }
  ] 
  },
  { username: 'myg',
  screenlist: [
    {
      screenname: 'screen q',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date','date', 'date',  'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 
      ]
    },
    {
      screenname: 'screen w',
      isArchived: false,
      screenTime: [
      'date', 'date', 
      ]
    },
    {
      screenname: 'screen e',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date',
      ]
    },
    {
      screenname: 'screen r',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date'
      ]
    },
    {
      screenname: 'screen t',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date','date', 'date',  'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 
      ]
    },
    
  ] 
  },
  { username: 'tomy',
  screenlist: [
    {
      screenname: 'screen a',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date'
      ]
    },
    {
      screenname: 'screen b',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date'
      ]
    },
    {
      screenname: 'screen c',
      isArchived: false,
      screenTime: [
      'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date', 'date'
      ]
    }
  ] 
  },
 ]