import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexWrapper, FullScreenBackgroundImage, SignInWrapper } from '../../components/wrapper/Wrapper.style'
import { screens } from '../../const/path'
import InputBox from '../../components/InputBox/index'
import { LoginButton } from '../../components/button/Button.style'
import { light_background, primary_color, red_color } from '../../const/color_theam'
import { Amplify, Auth } from 'aws-amplify'
import { Label } from '../../components/typography/Label.style'
import { useState } from 'react'
import { awsconfig } from '../../const/awsconfig'
import { setLocalStorage } from '../../utils/setLocalStorage'
import ScreenLayout from '../../components/ScreenLayout/index'
import { LinkText } from '../../components/typography/LinkText.style'
import Logo from '../../assets/logo/viewbox.svg'
import './style.css'
import { getUserName } from '../../utils/commonUtils'

const Index = () => {
  const navigate = useNavigate()
  const [userCredential, setUserCredential] = useState({ username: getUserName(), password: '' });
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false)

  const handleInputChange = (event) => {
    setError(null)
    const { name, value } = event.target
    setUserCredential({ ...userCredential, [name]: value })
  };

  Amplify.configure(awsconfig)
  const signIn = () => {
    setLoader(true)
    const { username, password } = userCredential
    Auth.signIn(username, password).then(resp => {
      setLoader(false)
      setLocalStorage('userdata', resp)
      navigate(screens, { state: "data" });
    }).catch(err => {
      setLoader(false)
      const { message = "Login Error" } = err
      setError(message)
    })
  }

  return (
    <FullScreenBackgroundImage>
      <FlexWrapper >
        <FlexWrapper width='50%' height='100vh' justify='center' align='center' >
          <ScreenLayout />
        </FlexWrapper>

        <FlexWrapper width='50%' height='100vh'
           align='center' justify='center'
        >
          <SignInWrapper>
          <img src={Logo} alt="media"  width="200px" />
           <p className='login-heading'>Login</p>
          <FlexWrapper
            direction='column' align='flex-start'
          >
            <InputBox color={light_background}  border={true} label={'User name'} name='username'
              value={userCredential.username} handleInputChange={handleInputChange} placeholder='Enter Username' />
            <InputBox  color={light_background}  border={true} label={'Password'} type='password' name='password' placeholder='Enter Password'
              value={userCredential.password} handleInputChange={handleInputChange} />
            {error && <Label color={red_color} >{error}</Label>}
            <FlexWrapper justify='space-between'
              width='100%' align='center' paddingTop='32px'
            >
              <LinkText>Forgot Password ?</LinkText>
              <FlexWrapper justify='space-between'
                width='48%' align='center'
              >
                <LinkText>Sign up</LinkText>
                <LoginButton onClick={signIn} color={primary_color} > Login 
                { loader && <div className='loader-small' />  }
                 </LoginButton>
              </FlexWrapper>
            </FlexWrapper> 
           </FlexWrapper>
           </SignInWrapper>
        </FlexWrapper> 

      </FlexWrapper>
    </FullScreenBackgroundImage>
  )
}

export default Index