import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { getBillableScreenCount, getMonthInFormat } from '../../utils/commonUtils';
import moment from 'moment';

const styles = StyleSheet.create({
  page: { backgroundColor: "#d11fb6", color: "white" },
  section: { margin: 10, padding: 10 },
  viewer: { width: '100%', height: '100%' },
  container: { padding: 20 },
  header: { marginBottom: 20 },
  headerText: { fontSize: 16, fontWeight: 'bold', marginBottom: 40, textAlign: 'center', marginTop: 20 },
  billInfoText: { fontSize: 12, fontWeight: 'normal', marginBottom: 5, textAlign: 'left' },
  billEndText: { fontSize: 10, fontWeight: 'normal', marginBottom: 20, textAlign: 'center', marginTop: 280 },
  heading: { fontSize: 14, fontWeight: 'normal', marginBottom: 20, textAlign: 'left', marginTop: 20 },
  table: { display: 'table', width: 'auto', borderStyle: 'solid', borderWidth: 1, borderColor: '#bfbfbf', marginBottom: 10 },
  tableRow: { flexDirection: 'row' },
  tableCell: { padding: 5, fontSize: 10, textAlign: 'left' },
  tableCellLeft: { padding: 5, fontSize: 10, textAlign: 'left', width: '40px', },
  tableCellMiddel: { padding: 5, fontSize: 10, textAlign: 'left', width: '240px', },
  tableHeader: { backgroundColor: '#f2f2f2' },
  logo: { width: 30, height: 30, marginBottom: 10 },
  billTableCell: { padding: 5, fontSize: 10, textAlign: 'left', },
});


const pdf = ({ selectedUserInfo, month }) => {
  const { email, screens } = selectedUserInfo
  const sortedUserScreens = screens.sort((a, b) => {
    const nameA = a.screenName.toUpperCase();
    const nameB = b.screenName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const billCount = getBillableScreenCount(screens, month)
  const currentDate = moment();
  const dueDate = currentDate.add(15, 'days');
  const formattedDueDate = dueDate.format('MMMM DD, YYYY');
  const currentDateInFormat = moment().format('MMMM DD, YYYY')
  const billNumber = `#${moment().format('HHmmss')}-${moment().format('YYMMDD')}`;

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page>
          <View style={styles.container}>
            <View style={styles.header}>
              <Image src={require(`../../assets/logo/viewbox_new.png`)} style={styles.logo} />
              <Text style={styles.headerText}>Viewbox Invoice</Text>
              <Text style={styles.billInfoText}>Invoice Number: {billNumber}</Text>
              <Text style={styles.billInfoText}>Date: {currentDateInFormat}</Text>
              <Text style={styles.billInfoText}>Billing Period: {getMonthInFormat(month) }</Text>
              <Text style={styles.billInfoText}>Due Date: {formattedDueDate}</Text>
              <Text style={{ ...styles.billInfoText, marginTop: 10 }}>Bill To:</Text>
              <Text style={styles.billInfoText}>Customer Name:  {(email && email.split('@')[0]) || '----'} </Text>
              <Text style={styles.billInfoText}>Email: {email || '----'} </Text>
              <Text style={styles.billInfoText}>Phone: ---- </Text>
            </View>

            <View style={{ ...styles.table, marginTop: 40 }}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={{ ...styles.billTableCell, width: '40%' }}>Description</Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}>Qty / No</Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}>Price</Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}>Amount</Text>
              </View>


              <View style={styles.tableRow}>
                <Text style={{ ...styles.billTableCell, width: '40%' }}> Total Billable screens </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}>{billCount} Screens </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> 300 INR</Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}>{billCount * 300} INR</Text>
              </View>

              <View style={styles.tableRow}>

                <Text style={{ ...styles.billTableCell, width: '40%' }}> Additional Features </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ---- </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}>---- </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ----</Text>
              </View>

              <View style={styles.tableRow}>

                <Text style={{ ...styles.billTableCell, width: '40%' }}> Extra Storage </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ---- </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ----</Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ----</Text>
              </View>

              <View style={styles.tableRow}>
                <Text style={{ ...styles.billTableCell, width: '40%' }}>  Technical Support </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ---- </Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ----</Text>
                <Text style={{ ...styles.billTableCell, width: '20%' }}> ----</Text>
              </View>

              <View style={{ ...styles.tableRow, borderTop: '1px solid #bfbfbf' }}>
                <Text style={{ ...styles.billTableCell, width: '40%' }}> </Text>
                <Text style={{ ...styles.billTableCell, width: '19%' }}>  </Text>
                <Text style={{ ...styles.billTableCell, width: '20%', fontSize: 12, fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}> Total </Text>
                <Text style={{ ...styles.billTableCell, width: '20%', fontSize: 12, fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}> {billCount * 300} INR </Text>
              </View>
            </View>
            <Text style={styles.billEndText}>Please remit payment by the due date. Thank you for choosing viewbox</Text>
          </View>
        </Page>
        <Page>
          <View style={styles.container}>
            <Text style={styles.heading}>Screen Details</Text>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={[styles.tableCellLeft]}>No</Text>
                <Text style={[styles.tableCellMiddel]}>Screen Name</Text>
                <Text style={[styles.tableCell]}>Active Days</Text>
              </View>
              {sortedUserScreens.map((screen, index) => {
                var februaryData = screen.analytics && screen.analytics[month]
                return (
                  <View key={index} style={styles.tableRow}>
                    <Text style={[styles.tableCellLeft]}>{index}</Text>
                    <Text style={[styles.tableCellMiddel]}>{screen.screenName}</Text>
                    <Text style={[styles.tableCell]}>{februaryData?.length || 0} Days</Text>
                  </View>
                )
              })}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default pdf