import React from 'react'
import { TitleOne } from '../typography/Title.style'
import { Messege } from '../typography/Caption.style'
import { SkipButton } from '../button/Button.style'

const Index = ({ handleWarnning, showskip }) => {
  return (
    <>
          <div className='subscripion-popup' />
          <div className='subscripion-wrapper'>
          <img alt='' src={require('../../assets/images/card.png')} width={'400px'} />
          <div className='subscripion-message'>
          <TitleOne color={'#fff'} > Payment warning </TitleOne>
            <Messege marginTop='14px' color={'#fff'}>  
            Your subscription plan has concluded. Kindly reach out to our customer support team for assistance. </Messege> 
            {
              showskip &&  <SkipButton onClick={() => {  handleWarnning (false) } }> skip now </SkipButton>
            }
          </div>
          </div>
          </>
  )
}

export default Index