import React, { useEffect, useState } from 'react'
import './style.css'
import { currentMonth, getBillableScreenCount, getMonthInFormat, getUserEmail, getUserName, lastMonth } from '../../utils/commonUtils';
import axios from 'axios';
import Pdf from './pdf'
import { useNavigate } from 'react-router-dom';


const Index = () => {
    const [user, setUser] = useState(null)
    const [showPdf, setShowPdf] = useState(false)
    const [selectedUserInfo, setUserInfo] = useState(false)
    const [ isCurrentMonth, setMonth ] = useState(true)
    const navigate = useNavigate();
    
    useEffect(() => {
        const listUser = {
            url: '/listUsers',
            method: 'GET',
            data: JSON.stringify({ username: getUserName() })
        }
        axios(listUser)
            .then((response) => {
                const { data: { users } } = response
                setUser(users)
                console.log('@@@ list user api resp', users)
            })
            .catch((error) => {
                console.log('list user api resp', error)
            })
    }, [])

    useEffect(() => {
        const adminEmail = [ 'maharoofhira@gmail.com', 'muqsithck@gmail.com', 'Muhassin@viewbox.io', 'tomym619@gmail.com' ]
        if (!adminEmail.includes(getUserEmail())) {
            navigate('/screens');
        }
      }, [navigate]);

    const generateBill = (item) => {
        setShowPdf(true)
        setUserInfo(item)
    }

    return (
        <div className='admin-wrapper'>
            <h1>User Details</h1> 
            <button className={ isCurrentMonth ? 'admin-button' : 'admin-button-active' } onClick={() => setMonth(false) } > {getMonthInFormat(lastMonth)} </button>
            <button className={ isCurrentMonth ? 'admin-button-active' : 'admin-button' } onClick={() => setMonth(true) } > {getMonthInFormat(currentMonth)} </button> 
            <table id="customers">
                <tr>
                    <th>User Name</th>
                    <th>Screens</th>
                    <th>Total Screens</th>
                    <th>Billable Screens</th>
                    <th>Generate Bill</th>

                </tr>
                {
                    user && user.map((item) => {

                        const userScreens = (item.screens && item.screens.length > 0 && item.screens) || []
                        const sortedUserScreens = userScreens.sort((a, b) => {
                            const nameA = a.screenName.toUpperCase();
                            const nameB = b.screenName.toUpperCase();

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0;
                        });

                        const billCount = getBillableScreenCount(item.screens, isCurrentMonth ?  currentMonth : lastMonth  )
                    
                        return (
                            <tr>
                                <td>{(item.email)}</td>
                                <td>
                                    <div className='screen-info-container'>
                                        <div className='screen-name-title'>
                                            Screen 
                                        </div>
                                        <div className='screen-count-title'>
                                            { isCurrentMonth ?  getMonthInFormat(currentMonth) : getMonthInFormat(lastMonth) }
                                        </div>
                                    </div>
                                    {sortedUserScreens.map((screen) => {
                                        var februaryData = screen.analytics && screen.analytics[ isCurrentMonth ?  currentMonth : lastMonth ]
                                        return (
                                            <div className='screen-info-container-margin'>
                                                <div className='screen-name'>
                                                    {screen?.screenName}
                                                </div>
                                                <div className='screen-count'>
                                                    {februaryData?.length || 0} Days
                                                </div>
                                            </div>
                                        )
                                    })}
                                </td>
                                <td>
                                    {
                                        `${item.screens?.length} Screens`
                                    }
                                </td>
                                <td>
                                    {
                                        `${billCount} Screens`
                                    }
                                </td>

                                <td>
                                    <button className='admin-button-active' onClick={() => generateBill(item)} >Generate Bill</button>
                                </td>

                            </tr>
                        )
                    })
                }
            </table>

            {
                showPdf &&
                <>
                    <div className='pdf-bg' onClick={() => setShowPdf(false)} ></div>
                    <div className='pdf-container'>
                        <Pdf selectedUserInfo={selectedUserInfo} month={isCurrentMonth ?  currentMonth : lastMonth} />
                    </div>
                </>
            }

        </div>
    )
}

export default Index