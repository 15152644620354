import styled from 'styled-components'
import {light_background, secondary_color} from '../../const/color_theam'
import { device } from '../../const/breakpoints'

export const Searchbar = styled.input`
width: 700px;
height: 50px;
border: none;
background-color: ${light_background};
border-radius: 30px;
color: ${secondary_color};
padding-left: 40px;
font-size: 20px;
font-family: 'Roboto', sans-serif;
font-weight: 400;

&::placeholder {
       color: ${secondary_color};
       font-size: 20px;
       font-family: 'Roboto', sans-serif;
       font-weight: 100;
       letter-spacing: 2px;
   }

   &:focus {
        outline: none;
        box-shadow: 0px 0px 2px ;
    }

    @media ${device.tablet} {
        width: 350px;
      }
      
      @media ${device.laptop} {
        width: 700px;
      }
      
      @media ${device.desktop} {
        width: 700px;
      }
    
`