import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Screens from './pages/screens/index'
import SignIn from './pages/signIn/index'
import Media from './pages/media/index'
import Playlist from './pages/playlist/index'
import Scheduler from './pages/scheduler/index'
import Menu from './components/Menu/index'
import ScreenManage from './pages/ScreenManage/index'
import PlaylistManage from './pages/PlaylistManage/index'
import Player from './pages/Player/index'
import Admin from './pages/Admin/index'
import Apps from './pages/apps/index'
import { AppStyle, MenuWrapper, RoutesWrapper } from './App.style'
import './index.css'
import { Store } from './store/store'
import SubscriptionWarning from './components/SubscriptionWarning/index'
import { setSubacriptionWarnning } from './actions/index'

const App = () => {
  const { state, dispatch } = useContext(Store)
  const { userData, showSubscriptionWarnning } = state
  const handleWarnning = () => {
    setSubacriptionWarnning(false, dispatch)
  }

  return (
    <Router>
      <AppStyle>
        {
          window.location.pathname !== '/' && userData.subscription !== 'active' && showSubscriptionWarnning &&
          <SubscriptionWarning handleWarnning={handleWarnning} showskip={window.location.pathname === '/screens'} /> 
        }
        <MenuWrapper>
          <Menu />
        </MenuWrapper>
        <RoutesWrapper>
          <Routes>
            <Route path='/screens' element={<Screens />} />
            <Route path='/screens/manage/:id/:name' element={<ScreenManage />} />
            <Route path='/' element={<SignIn />} />
            <Route path='/media' element={<Media />} />
            <Route path='/playlist' element={<Playlist />} />
            <Route path='/player' element={<Player />} />
            <Route path='/scheduler' element={<Scheduler />} />
            <Route path='/apps' element={<Apps />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/playlist/manage/:id/:name' element={<PlaylistManage />} />
          </Routes>
        </RoutesWrapper>
      </AppStyle>
    </Router>
  )
}

export default App