import React from 'react'
// import { useState } from 'react'
import { light_color, primary_color } from '../../const/color_theam'
import { SmallButton } from '../button/Button.style'
import { Caption } from '../typography/Caption.style'
import { formatBytes } from '../../utils/commonUtils'
import { FlexWrapper } from '../wrapper/Wrapper.style'
// import { FlexWrapper } from '../wrapper/Wrapper.style'
// import { StlyedText, RoundButton, ColorGridWrapper, SelectButton, IconButton } from './info.style'


const Index = ({ clearMemmory, screenInfo }) => {


  const { screenMacID, screenManufacturer, screenModel, screenName, screenMemory, appVersion = '1.4.4' } = screenInfo
  const { totalSpace, freeSpace } = screenMemory || {}

  return (
    <>
      {
        screenName && <>
          <Caption color={light_color}> Screen  Name </Caption>
          <p>{screenName}</p>
        </>
      }

      {
        appVersion && <>
          <Caption color={light_color}> App Version </Caption>
          <p>{appVersion}</p>
        </>
      }

      {
        screenModel && <>
          <Caption color={light_color}> Screen Type </Caption>
          <p>{screenModel}</p>
        </>
      }


      {
        screenManufacturer && <>
          <Caption color={light_color}> Screen Manufacturer </Caption>
          <p>{screenManufacturer}</p>
        </>
      }


      {
        screenMacID && <>
          <Caption color={light_color}> Screen MAC </Caption>
          <p>{screenMacID}</p>
        </>
      }

      {
        screenMemory && <>
          <Caption color={light_color}> Screen  Memmory </Caption>
          <FlexWrapper >
          <p style={{marginRight: '40px'}} > {formatBytes(freeSpace)} / {formatBytes(totalSpace)}  </p>

      <SmallButton color={primary_color} active onClick={clearMemmory} > CLEAR MEMORY </SmallButton>
      </FlexWrapper>
        </>
      }
    </>
  )
}

export default Index