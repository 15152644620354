import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton, IconsButtonSmall } from '../../components/button/Button.style'
import { Title } from '../../components/typography/Title.style'
import { AppWrapper, FlexWrapper } from '../../components/wrapper/Wrapper.style'
import { primary_color, red_color, theam_black } from '../../const/color_theam'
import ScreenMediaCard from '../../components/ScreenMediaCard/index'
import axios from 'axios'
import { playlist } from '../../const/path'
import EmptyItem from '../../components/EmptyItems'
import MediaBar from '../../components/MediaBar'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isEmptyArray, isNonEmptyArray, isNull } from '../../utils/utilsFunctions'
import Loader from '../../components/Loader/index'
import { getUserName } from '../../utils/commonUtils'
import InputBox from '../../components/InputBoxEdit'
import DropDownPopup from '../../components/DropDownPopup'


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const MediaListCards = React.memo(function MediaListCards({ playListMedia, handleMediaRemove, handleSettings }) {
  return playListMedia.map((item, i) => (
    <Draggable draggableId={item.id + i} index={i}>
      {provided => (
        <ScreenMediaCard
          provided={provided} handleSettings={handleSettings}
          item={item} key={i} handleMediaRemove={handleMediaRemove}
        />
      )}
    </Draggable>
  ));
});


const Index = () => {
  const [playListMedia, setPlayListMedia] = useState()
  const [selectedMedia, setSelectedMedia] = useState([])
  const [isShowSync, setShowSync] = useState(false)
  const navigate = useNavigate()

  const { name, id } = useParams()
  useEffect(() => {
    const getPlaylist = {
      url: '/getPlaylist',
      method: 'POST',
      data: JSON.stringify({ id: id })
    }
    axios(getPlaylist)
      .then(res => {
        console.log('get playlist res', res)
        const { data } = res
        const { media } = data
        setPlayListMedia(media)
      })
      .catch(err => console.log('get playlist error', err))
  }, [id])

  const updatePlaylistMedia = (item) => {
    if (playListMedia && playListMedia.length > 0) {
      setPlayListMedia([...playListMedia, item])
    } else {
      setPlayListMedia([item])
    }
  }

  const addMediaToPlaylist = (item) => {
    setSelectedMedia([...selectedMedia, item])
    setShowSync(true)
    const data = {
      id: id,
      name,
      media: [...playListMedia, item]
    }
    const addMediaToPlaylist = {
      url: '/updatePlaylist',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(addMediaToPlaylist)
      .then(res => {
        console.log('media added to playlist')
      })
      .catch(err => console.log('media added to playlist failed', err))
  }

  const handleMediaRemove = (media) => {
    const newList = playListMedia.filter(item => item.id !== media.id)
    const data = {
      id: id,
      name,
      media: newList
    }
    const addMediaToPlaylist = {
      url: '/updatePlaylist',
      method: 'POST',
      data: JSON.stringify(data)
    }
    setPlayListMedia(newList)
    setShowSync(true)
    axios(addMediaToPlaylist)
      .then(res => {
        console.log('media removed from playlist', res)
      })
      .catch(err => console.log('media removed from playlist failed', err))
  }

  const deletePlaylist = () => {
    const listPlaylist = {
      url: '/deletePlaylist',
      method: 'POST',
      data: JSON.stringify({ id })
    }
    axios(listPlaylist)
      .then((response) => {
        navigate(playlist, { state: 'data' })
        console.log(' PLAYLIST delted', response)
      })
      .catch((error) => {
        console.log(' PLAYLIST delte failed', error)
      })
  }

  function onDragEnd(result) {
    console.log("on drag end ##")
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }


    const newList = reorder(
      playListMedia,
      result.source.index,
      result.destination.index
    );
    setPlayListMedia(newList);
  }



  const handlePlayerSync = () => {
    const listPlaylist = {
      url: '/syncPlaylist',
      method: 'POST',
      data: JSON.stringify({ playlistId: id, username: getUserName() })
    }
    setShowSync(false)
    axios(listPlaylist)
      .then((response) => {
        console.log('syncPlaylist success')
      })
      .catch((error) => {
        console.log('syncPlaylist delte failed', error)
      })
  }


  const [ playlistTitle, setTitle ] = useState(name)
  const [ isEditTitle, setEditTitle ] = useState(false)
  
  const handleNameChange = (e) => {
  setTitle(e.target.value)
  }

  const toggleTitleEdit = () => {
    setEditTitle(!isEditTitle)
  }

  const playlistTitleChange = () => {
    const data = {
      id: id,
      name: playlistTitle,
    }
    const startPlayerApi = {
      url: '/updatePlaylist',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(startPlayerApi)
      .then(res => {
        setEditTitle(false)
      })
      .catch(() => {setTitle(name)
        setEditTitle(false)})
  }

  const handleSettings = (item) => {
    console.log("click settings")
    setCurrentMedia(item)
    openPopup()
  }

  const [showPopup, setShowPopup] = useState(false)
  const [playlistLoader, setPlaylistLoader] = useState(false)
  const [ currentMedia, setCurrentMedia ] = useState(null)

  const closePopup =()=> {
    setShowPopup(false)
  }

  const openPopup =()=> {
    setShowPopup(true)
  }

  const playlistUpdate = () => {
    setPlaylistLoader(true)
    setShowSync(true)
    const updatedPlayListMedia = playListMedia.map(item => {
      if (item.id === currentMedia.id) {
        return currentMedia
      }
      return item;
    });
    const data = {
      id: id,
      name,
      media: updatedPlayListMedia
    }
    const addMediaToPlaylist = {
      url: '/updatePlaylist',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(addMediaToPlaylist)
      .then(res => {
        console.log('media added to playlist')
        closePopup()
        setPlaylistLoader(false)
        setPlayListMedia(updatedPlayListMedia)
      })
      .catch(err => {
        setPlaylistLoader(false)
        closePopup()
        console.log('media added to playlist failed', err)})
  }

  const handleDropdownChange = (event) => {
    if(event.target.name === 'view'){
      setCurrentMedia( { ...currentMedia, view: event.target.value } )
    } else if(event.target.name === 'duration'){
      setCurrentMedia( { ...currentMedia, duration: parseInt(event.target.value) } )
    }
  }
  
  return (
    <AppWrapper top='3%'>
      {
        showPopup &&
        <DropDownPopup
        onCancel={closePopup} onConfirm={playlistUpdate}
        heading='Media Settings'
        confirmTitle='Confirm' loading={playlistLoader}
        currentMedia={currentMedia}
        handleChange={handleDropdownChange}
        />
      }
 

          <FlexWrapper align='flex-start' justify='space-between'  paddingTop='0px' marginBottom="20px">
      {/* <Title color={theam_black} size='24px'>  {name}   </Title> */}
      <FlexWrapper align='center' paddingTop='0px'  marginBottom="20px">
      {
        isEditTitle ?
        <InputBox label={'title'} type="text" name={'name'} value={playlistTitle} handleInputChange={handleNameChange} onCancel={toggleTitleEdit} onSubmit={playlistTitleChange} /> :
        <>
        <Title color={theam_black} size='24px'> {playlistTitle}</Title> 
        <i onClick={toggleTitleEdit} className="fa fa-pencil-square-o" style={{  fontSize: '18px' ,  marginLeft: '16px', color: primary_color , cursor: 'pointer' }} ></i>
        </>
       }
       </FlexWrapper>
      
      <FlexWrapper align='center'  >
      <IconsButtonSmall color={primary_color} disabled={!isShowSync} onClick={handlePlayerSync} > 
          <i className="fa-solid fa-rotate" style={{ marginRight: '4px' }} /> { isShowSync ? 'Sync Media' : 'Media synced' }
      </IconsButtonSmall>  
      <PrimaryButton color={red_color} onClick={deletePlaylist}> Delete </PrimaryButton>
      </FlexWrapper>
    </FlexWrapper>
      <FlexWrapper>
        <FlexWrapper width='50%'  overflow='scroll' height='80vh' direction='column' paddingTop='30px'>
          {isNonEmptyArray(playListMedia) &&
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <MediaListCards playListMedia={playListMedia} handleMediaRemove={handleMediaRemove} handleSettings={handleSettings} />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          }
          {
            isEmptyArray(playListMedia) && <EmptyItem />
          }
          {
            isNull(playListMedia) &&
            <FlexWrapper height="50%" width="100%" align='center' justify='center' >
              <Loader />
            </FlexWrapper>
          }


        </FlexWrapper>
        <FlexWrapper justify='flex-end' width='50%' paddingTop='30px'>
          <MediaBar
            {...{
              isPlaylist: true,
              addMediaToScreen: addMediaToPlaylist,
              updateScreenMedia: updatePlaylistMedia
            }}
          />
        </FlexWrapper>
      </FlexWrapper>
    </AppWrapper>

  )
}

export default Index
