import styled from 'styled-components'
import { device } from '../../const/breakpoints'
import SigninBackground from '../../assets/images/home-bg.jpg'

export const FlexWrapper = styled.div`
display: flex;
align-items: ${(props) => props.align || 'none'} ;
justify-content:  ${(props) => props.justify || 'none'};
height:  ${(props) => props.height || 'auto'} ;
width: ${(props) => props.width || 'auto'};
padding-left: ${(props) => props.paddingLeft || '0px'} ;
padding-right: ${(props) => props.paddingRight || '0px'} ;
padding-top: ${(props) => props.paddingTop || '0px'};
padding-bottom: ${(props) => props.paddingBottom || '0px'};
margin-bottom: ${(props) => props.marginBottom || '0px'} ;
margin-top: ${(props) => props.marginTop || '0px'};
margin-right: ${(props) => props.marginRight || '0px'} ;
margin-left: ${(props) => props.marginLeft || '0px'} ;
flex-direction:  ${(props) => props.direction || ''};
background-color: ${(props) => props.bgColor || ''};
overflow: ${(props) => props.overflow || 'visible'};
::-webkit-scrollbar {
    display: none;
}
`
export const DragaAndDropWrapper = styled.div`
display: flex;
height: ${(props) => props.h || '250px'}  ;
width: ${(props) => props.w || '630px'};
background-color: #F5F5F5;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`

export const CardGridWrapper = styled.div`
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 40px;
  padding-top: ${(props) => props.paddingTop || '20px'};
  width:fit-content;
  /* padding-bottom: 100px; */

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);

  }

  @media ${device.desktop} {
    grid-template-columns: repeat(5, 1fr);

  }
`

export const ScrollWrapper = styled.div`
  overflow: scroll;
  height: 70vh;
  ::-webkit-scrollbar {
    display: none;
}
`
export const CardListWrapper = styled.div`
  padding-top: ${(props) => props.paddingTop || '0px'};
`

export const MediaPopUpGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  width: 60vw;
`
export const MediaListGridWrapper = styled.div`
display: flex;
flex-direction: column;
`

export const MediaGridWrapper = styled.div`
display: grid;
grid-row-gap: 20px;
justify-content: center;
align-items: flex-start;
width: 72vw;
height: 70vh;
overflow: scroll;
padding-bottom: 10vh;
::-webkit-scrollbar {
    display: none;
}

@media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);

  }

  @media ${device.desktop} {
    grid-template-columns: repeat(5, 1fr);

  }
`
export const AppWrapper = styled.div`
height: auto;
padding-left: 5%;
padding-right: 5%;
padding-top: ${(props) => props.top || 'transparent'};
`
export const MediaIconWrapper = styled.div`
height: 100%;
border-radius: 1px solid;
border-radius: 10px ;
width: 16%;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 15px;
`
export const FullScreenBackgroundImage = styled.div`
position: absolute;
height: 100vh;
width: 100vw;
background-image: url(${SigninBackground});
background-color: black;
background-position: center; 
background-repeat: no-repeat; 
background-size: cover; 
top: 0;
left: 0; 
z-index: 15;
`

export const SignInWrapper = styled.div`
background-color: rgba(0, 0, 0, .3);
border-radius: 12px;
display: flex;
flex-direction: column;
padding: 80px 70px;
justify-content: center;
align-items: center;

@media ${device.tablet} {
  height: 400px;
width: 300px;
      }
      
      @media ${device.desktop} {
        height: 450px;
width: 350px;
      }
`
export const PlayerWrapper = styled.div`
position: absolute;
height: 100vh;
width: 100vw;
background-color: #000 ;
background-repeat: no-repeat;
top: 0;
left: 0; 
display: flex;
justify-content: end;
align-items: center;
`


// background-image: url(${SigninBackground});

export const ImageWrapper = styled.div`
position: absolute;
height: 100vh;
width: 100vw;
background-image:  ${props => `url(${props.image})`};
background-position: center;
background-size: cover;
background-color: #000;
background-repeat: no-repeat;
top: 0;
left: 0; 
display: flex;
justify-content: end;
align-items: center;
`
export const SelectedWrapper = styled.div`
position: absolute;
height: 160px;
width: 160px;
background-color: black;
opacity: .7;
`

export const SmallImageWrapper = styled.div`
height:${props => props.size || '160px'};
width: ${props => props.size || '220px'};
background-image:  ${props => `url(${props.image})`};
background-position: center;
background-size: cover;
background-color: #000;
background-repeat: no-repeat;
display: flex;
justify-content: end;
align-items: center;
border-radius: 8px;
`
export const ExtraSmallImageWrapper = styled.div`
user-select:none;
height: 60px;
width: 60px;
background-image:  ${props => `url(${props.image})`};
background-position: center;
background-size: cover;
background-color: #000;
background-repeat: no-repeat;
display: flex;
justify-content: end;
align-items: center;
border-radius: 6px;
`
export const StaticWrapper = styled.div`
display: flex;
justify-content: space-between;
position: -webkit-sticky;
position: sticky;
top: 0;
background-color: ${props => props.bgColor || '#fff'};
padding-top: 50px;
`



export const BottomPannel = styled.div`
position: fixed;
height: 50px;
width: 85%;
background-color: #5e81f4;
bottom: 0px;
right: 0px; 
display: flex;
justify-content: flex-end;
align-items: center;
z-index: 5;
`
export const NotificationWrapper = styled.div`
position: absolute;
height: auto;
width: 12vw;
background-color: #F5F5FB;
bottom: 100px;
left: 20px; 
display: flex;
justify-content: flex-end;
align-items: center;
z-index: 5;
border-radius: 12px;
`







