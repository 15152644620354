import moment from "moment"


const colorArr =[ '#1abc9c','#3498db','#9b59b6','#f1c40f','#e67e22','#e74c3c','#2ecc71', '#5f27cd', '#ff9ff3']

export const cells = [
    'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7',
    'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7',
    'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7',
    'D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7',
    'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7',
    'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7',
    'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7',
    'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'H7',
    'I1', 'I2', 'I3', 'I4', 'I5', 'I6', 'I7',
    'J1', 'J2', 'J3', 'J4', 'J5', 'J6', 'J7',
    'K1', 'K2', 'K3', 'K4', 'K5', 'K6', 'K7',
    'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7',
  ]
  export const days = [
    'MONDAY', 'TUESDAT', 'WEDNESDAY', 'THURSDAT', 'FRIDAY', 'SATURDAY', 'SUNDAY'
  ]
  export const time = [
    '12:00 AM', '02:00 AM', '04:00 AM', '06:00 AM', '08:00 AM', '10:00 AM', '12:00 PM', '02:00 PM', '04:00 PM', '06:00 PM', '08:00 PM', '10:00 PM',
  ]

  export const timeMapArray = {
    A: '00:00', B:'02:00', C: '04:00', D: '06:00',
    E: '08:00', F:'10:00', G: '12:00', H: '14:00',
    I: '16:00', J:'18:00', K: '20:00', L: '22:00',
  }

  export const dummy = [
    {
      name: 'Sunday special',
      start: '08:00',
      end: '11:00',
      color: '#2ecc71',
      playlist: 'Test playlist',
      cellID: 'A7'
    },
  
    {
      name: 'Friday Night',
      start: '18:00',
      end: '22:00',
      color: '#9b59b6',
      playlist: 'Night ONE',
      cellID: 'A5'
    }
  ]
  
  

  export const getTop = (item) => {
    return 20.4 * parseFloat(item.start.replace(":", ".")) + (parseFloat(item.start.replace(":", ".")) * 0.4)
  }
  export const getHight = (item) => {
   const diff=  parseFloat(item.end.replace(":", ".")) - parseFloat(item.start.replace(":", "."));
   if(20.4 * diff < 70 ){
    return 70
   }
   return   20.4 * diff
  }

  export const getLeft = (item) => {
    const arr = item.cellID.split('')
   return 140 * (parseInt(arr[1]) - 1 ) + (parseInt(arr[1]))
   }

   export const getColor = () => {
    return colorArr[Math.floor((Math.random() * 8) + 1)]
   }

   export const getStartTimeFromCellId= (cellID) => {
    const arr = cellID.split('')
    return timeMapArray[arr[0]]
  }

  export const getEndTimeFromCellId= (cellID) => {
    const start = parseFloat(getStartTimeFromCellId(cellID))
    return moment().hour(start).minute('00').add(2,'hours').format("HH:mm");
  }


