import React from 'react'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import { Searchbar } from '../searchbar/Searchbar.style'
import { PrimaryButton } from '../button/Button.style'
import { primary_color, theam_black } from '../../const/color_theam'
import { Title } from '../typography/Title.style'

const Index = ({ title='', placeholder = null, buttonOne = null, buttonTwo = null, buttonTwoClick, searchOnChange, buttonOneClick, showSearch = true }) => {
  return (
    <FlexWrapper marginTop='36px' direction='column'>

      <FlexWrapper align='center' justify='space-between' width='100%'>
        <Title color={theam_black} size='22px'>  {title}  </Title>
        <div>
          {
            buttonOne &&
            <PrimaryButton color={primary_color} onClick={buttonOneClick}>{buttonOne}</PrimaryButton>
          }
          {
            buttonTwo &&
            <PrimaryButton color={primary_color} onClick={buttonTwoClick}>{buttonTwo}</PrimaryButton>
          }
        </div>
      </FlexWrapper>

     {
      showSearch &&
      <FlexWrapper marginTop='30px' marginBottom='30px' >
      <Searchbar placeholder={placeholder} onChange={searchOnChange} />
    </FlexWrapper>
     }
   

    </FlexWrapper>
  )
}

export default Index
