const updateScreenList = (state, screens) => {
   return { ...state, screens };
}

const addScreen = (state, screen) => {
   return { ...state, screens: [...state.screens, screen] };
}

const subscriptionWarnning = (state, value) => {
    return { ...state, showSubscriptionWarnning: value };
 }

export const reducer = (state, action) => {
  switch (action.type) {
      case 'UPDATE_SCREEN_LIST':
          return updateScreenList(state, action.data);
      case 'ADD_SCREEN':
          return addScreen(state, action.data);
      case 'SET_SUBSCRIPTION_WARNNING':
          return subscriptionWarnning(state, action.data);
      default:
          return state;
  }
};