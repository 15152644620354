export  const isNonEmptyArray = (arr) => {
    return Array.isArray(arr) && (arr.length > 0)
  }

  export  const isEmptyArray = (arr) => {
    return Array.isArray(arr) && (arr.length === 0)
  }
  
  export const isNull = (item) => {
     return item === null
  }
