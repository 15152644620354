import React from 'react'
import { Card, InfoWrapper, StatusIconWrapper } from '../Card/Card.style'
import { Title, TitleChara } from '../typography/Title.style'
import { Subtitle } from '../typography/Subtitle.style'
import { theam_blue, theam_violet, theam_white } from '../../const/color_theam'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import { dateToFromNowDailyWithOutTime } from '../../utils/dateInFormat'
import { isDeviceActive } from '../../utils/commonUtils'

const ScreenStatus = ({isOnline, screenName}) => {
  return (
    <StatusIconWrapper>
    <TitleChara color={ isOnline() ?  'black' : 'black'} size='16px'>  { screenName.charAt(0) }  </TitleChara>
    </StatusIconWrapper>
  )
}

const ScreenInfo = ({screenName, isOnline, content, screenLiveTime }) => {
  return (
    <InfoWrapper padding='6px' width="80%" >  
    <Title color={ isOnline() ?  theam_white : theam_white} size='16px'>  {screenName.toLowerCase()}  </Title>
    <FlexWrapper align='center' justify='center' paddingTop="4px"> 
    {
    isOnline() ?
    <Subtitle color={theam_white}> Online </Subtitle>
    :
    <Subtitle color={theam_white}> {screenLiveTime ? `Last active ${dateToFromNowDailyWithOutTime(screenLiveTime)}` : 'Last active long ago'} </Subtitle>
    }
    <Subtitle color={theam_white}>  {content}  </Subtitle>
    </FlexWrapper>
  </InfoWrapper>

  )
}


const index = ({screenName, content,status, screenLiveTime}) => {
  const isOnline = () => {
    return isDeviceActive(screenLiveTime)
  }
  
  return (
    <Card padding='10px 20px' width='200px' color={ isOnline() ? theam_blue : theam_violet} >
      <ScreenStatus status={status} screenName={screenName}  isOnline={isOnline} ></ScreenStatus>
      <ScreenInfo 
       {...{
        screenName, content,status, isOnline, screenLiveTime
       }}
      ></ScreenInfo>
    </Card>
  )
}

export default index