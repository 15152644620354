import React from 'react'
import { Subtitle } from '../../components/typography/Subtitle.style'
import { Title } from '../../components/typography/Title.style'
import { theam_black } from '../../const/color_theam'
import { getHight, getLeft, getTop } from './utils'
import './style.css'


const Schedules = ({item}) => {
  return (
                <div className='schedule'
                 style={{ backgroundColor: item.color, top: getTop(item), height: getHight(item), left: getLeft(item) }}  > 
                  <Title color={theam_black} size='14px'> {item.name} </Title>
                  <Subtitle marginRight='20px' color={theam_black}>  {item.playlist} </Subtitle>
                  <Subtitle marginRight='20px' color={theam_black}>    {item.start} : {item.end} </Subtitle>
                </div>
  )
}

export default Schedules