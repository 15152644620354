import styled from 'styled-components'
import { background_color, light_background } from './const/color_theam'

export const AppStyle = styled.div`
  display: flex;
  background-color: ${background_color};
  height: 100vh;
  position:relative
`

export const SubscriptionPopup = styled.div`
  
`

export const MenuWrapper = styled.div`
background-color: ${light_background} ;
width: 15%;
`
export const RoutesWrapper = styled.div`
width: 85%;
`


