import React from 'react'
import { AppWrapper, MediaGridWrapper } from '../../components/wrapper/Wrapper.style'
import './style.css'
import counter from '../../assets/app-icon/counter.png'
import calendar from '../../assets/app-icon/calendar.jpeg'
import menu from '../../assets/app-icon/menu-icon.jpeg'
import notice from '../../assets/app-icon/notice-board.jpeg'
import trainTime from '../../assets/app-icon/train-time.jpeg'
import weather from '../../assets/app-icon/weather.jpg'


const apps = [
    { name: 'counter', url: counter },
    { name: 'calendar', url: calendar },
    { name: 'menu', url:  menu},
    { name: 'notice', url: notice },
    { name: 'trainTime', url:  trainTime},
    { name: 'weather', url:  weather},
]

const Index = () => {
    console.log("####")
    return (
        <AppWrapper >
            <MediaGridWrapper>
                {
                    apps && apps.map((item) =>
                    <div className='app-wrapper'>
                       <div  className='app-cover' style={{backgroundImage: `${item.url}` }}  >
                       </div> 
                    </div>
                    )
                }
            </MediaGridWrapper>
        </AppWrapper>
    )
}

export default Index
