/* eslint-disable max-len */
import React from 'react';

/* --------------------------------------------- */
const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64.949" height="50.21" viewBox="0 0 64.949 50.21">
  <g id="_3084022671539681126" data-name="3084022671539681126" transform="translate(0 -33.378)">
    <path id="Path_7" data-name="Path 7" d="M50.122,49.911a17.711,17.711,0,0,0-35.344.006,16.9,16.9,0,0,0,2.134,33.671H42.631a1.325,1.325,0,0,0,0-2.65H16.912a14.252,14.252,0,0,1-.775-28.484,1.325,1.325,0,0,0,1.254-1.323c0-.026,0-.052,0-.078a15.061,15.061,0,0,1,30.122,0c0,.026,0,.053,0,.073a1.325,1.325,0,0,0,1.258,1.323,14.249,14.249,0,0,1,8.264,25.294A1.325,1.325,0,0,0,58.7,79.8a16.9,16.9,0,0,0-8.581-29.89Z" fill="#5a91ff"/>
    <path id="Path_8" data-name="Path 8" d="M140.966,136.4V153.3a1.325,1.325,0,1,0,2.65,0V136.4a1.325,1.325,0,1,0-2.65,0Z" transform="translate(-109.841 -79.245)" fill="#5a91ff"/>
    <path id="Path_9" data-name="Path 9" d="M123.129,116.114a1.325,1.325,0,0,0,.937-2.261l-7.577-7.577a1.325,1.325,0,0,0-1.873,0l-7.577,7.577a1.325,1.325,0,0,0,1.873,1.873l6.64-6.64,6.64,6.64A1.322,1.322,0,0,0,123.129,116.114Z" transform="translate(-83.102 -56.5)" fill="#5a91ff"/>
  </g>
</svg>
);
/* --------------------------------------------- */
export default UploadIcon;
/* --------------------------------------------- */
