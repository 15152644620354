import React from 'react'
import { FlexWrapper, DragaAndDropWrapper } from '../wrapper/Wrapper.style'
import { Popup, PopupWrapper } from './Popup.style'
import UploadIcon from '../../assets/icons/uploadicon'
import {  TitleThree } from '../typography/Title.style'
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF", 'mp4'];


const Index = ({closePopup , handleImage  }) => {

  return (
    <>
    <Popup onClick={closePopup} />
    <PopupWrapper width='650px' height="500px"  color='#fff' > 
    <FlexWrapper align='center' justify='center' marginBottom="40px" direction="column"  height="100%" >
    <>
    <FileUploader multiple handleChange={handleImage} name="file" types={fileTypes} >
     <DragaAndDropWrapper>
       <UploadIcon />  
    <TitleThree >Drag file here or Browse</TitleThree> 
    </DragaAndDropWrapper>
    </FileUploader>
    </>
    </FlexWrapper>
    </PopupWrapper>
  </>
  )
}

export default Index