import React from 'react'
import {  theam_black } from '../../const/color_theam'
import { Label } from '../../components/typography/Label.style'
import { Input } from './Input.style'


const index = ({label, handleInputChange, name, value }) => {
  return (
    <>
   <Label color={theam_black} >  {label} </Label>
   <Input  name={name} value={value} type="time" onChange={ handleInputChange } />
    </>
  )
}

export default index