import moment from "moment"
import { getLocalStorage } from "./getLocalStorage"

export const getToken = () => {
  const userdata = getLocalStorage('userdata')
  const { signInUserSession: { idToken: { jwtToken } } } = userdata
  return jwtToken
}

export const getUserName = () => {
  const userdata = getLocalStorage('userdata')
  const { username = '' } = userdata || {}
  return username
}

export const getUserEmail = () => {
  const userdata = getLocalStorage('userdata')
  const { attributes: { email } = {} } = userdata || {}
  return email || ''
}

export const isDeviceActive = (lastOnlineTime) => {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const lastOnlineTimeInSeconds = Math.floor(lastOnlineTime / 1000)
  const diff = currentTimeInSeconds - lastOnlineTimeInSeconds
  return diff < 180
}

export const getOnlineStatus = (time) => {
  return isDeviceActive(time) ? 'Online' : 'offline'
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const lastMonth = moment().subtract(1, 'months').format('MM-YYYY');
export const currentMonth = moment().format('MM-YYYY');
export const getMonthInFormat = (date) => moment(date, "MM-YYYY").format("MMMM YYYY")

export const getBillableScreenCount = (screens, month) => {
  if (!screens) return 0
  const februaryData = screens.map((screen) => {
    return screen.analytics && screen.analytics[month]
  }
  )
  const count = februaryData && februaryData.length > 0 && februaryData.filter((item) => item && item.length > 3)
  return count?.length || 0
}