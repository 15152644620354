import React from 'react'
import { primary_color, red_color, theam_black } from '../../const/color_theam'
import { PrimaryButton } from '../button/Button.style'
import { TitleOne } from '../typography/Title.style'
import { Popup, PopupWrapper } from './Popup.style'
import InputBox from '../InputBox/index'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import { Messege } from '../typography/Caption.style'
import { Label } from '../typography/Label.style'

const Index = ({ closeCreateScreenPopup, handleInputChange, create, data, lablelOne, lableTwo, nameOne, nameTwo, heading, message , confirmTitle='Create', error = null }) => {
  return (
    <>
      <Popup onClick={closeCreateScreenPopup} />
      <PopupWrapper >
        <FlexWrapper align='center' justify='center' marginBottom='30px'>
          <TitleOne color={theam_black}  >{heading}</TitleOne>
        </FlexWrapper>
        {
          lablelOne && <InputBox label={lablelOne} type="text" name={nameOne} value={data.name} handleInputChange={handleInputChange} />
        }
        {
          lableTwo && <InputBox label={lableTwo} type="text" name={nameTwo} value={data.keyCode} handleInputChange={handleInputChange} />
        }
        {error && <Label color={red_color} >{error}</Label>}
        {
          message && 
       <Messege>{message}</Messege>
        }
        <FlexWrapper align='center' justify='flex-end' marginTop='10px'>
          <PrimaryButton marginTop='20px' marginRight="25px" color='#F5F5FB' fontcolor='#060609' onClick={closeCreateScreenPopup} >Cancel</PrimaryButton>
          <PrimaryButton marginTop='20px' marginRight="0px" color={ confirmTitle === 'Delete' ? red_color : primary_color} onClick={create} >{confirmTitle}</PrimaryButton>
        </FlexWrapper>
      </PopupWrapper>
    </>
  )
}

export default Index