import React, { useContext, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { PrimaryButton, IconsButton, IconsButtonSmall } from '../../components/button/Button.style'
import { Title } from '../../components/typography/Title.style'
import { AppWrapper, FlexWrapper } from '../../components/wrapper/Wrapper.style'
import { green_color, light_color, primary_color, red_color, theam_black } from '../../const/color_theam'
import { Caption } from '../../components/typography/Caption.style'
import { Subtitle } from '../../components/typography/Subtitle.style'
import ScreenMediaCard from '../../components/ScreenMediaCard/index'
import { useEffect } from 'react'
import axios from 'axios'
import MediaBar from '../../components/MediaBar'
import { screens } from '../../const/path'
import { dateToFromNowDaily } from '../../utils/dateInFormat'
import EmptyItem from '../../components/EmptyItems'
import { isEmptyArray, isNonEmptyArray, isNull } from '../../utils/utilsFunctions'
import Loader from '../../components/Loader/index'
import { getUserName, isDeviceActive } from '../../utils/commonUtils'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputBox from '../../components/InputBoxEdit'
import WarningPopup from '../../components/WarrningPopup'
import { Store } from '../../store/store'
import { setSubacriptionWarnning } from '../../actions'


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const MediaListCards = React.memo(function MediaListCards({ screenMedia, handleMediaRemove, playlist }) {
  return screenMedia.map((item, i) => (
    <Draggable draggableId={item.id+i} index={i}>
      {provided => (
        <ScreenMediaCard
          playlist={playlist}
          provided={provided}
          item={item} key={i} handleMediaRemove={handleMediaRemove}
        />
      )}
    </Draggable>
  ));
});

const Index = () => {
  const { name, id } = useParams()
  const [screenInfo, setScreenInfo] = useState(null)
  const [selectedMedia, setSelectedMedia] = useState([])
  const [screenMedia, setScreenMedia] = useState(null)
  const navigate = useNavigate()
  const { status, id: screenId, screenState = 'Stop', screenLiveTime } = screenInfo || {}
  const [isPlaying, setIsPlaying] = useState(false)
  const [isScroll, setScroll] = useState(false)
  const [scrollText, setScrollText] = useState("scroll contents")
  const [scrollBg, setScrollBg] = useState("#F5F5F5")
  const [scrollFont, setScrollFont] = useState("#060609")
  const [playlist, setPlaylist] = useState(null)

  const handleScrollText = (event) => {
    const { value } = event.target
    setScrollText(value)
  }

  const updateScreenMedia = (item) => {
    if (screenMedia && screenMedia.length > 0) {
      setScreenMedia([...screenMedia, item])
    } else {
      setScreenMedia([item])
    }
  }

  const listPlayList = () => {
    const listPlaylist = {
      url: '/listPlaylist',
      method: 'POST',
      data: JSON.stringify({ username: getUserName() })
    }
    axios(listPlaylist)
      .then((response) => {
        const { data: { playlist: pl } } = response
        setPlaylist(pl)
        console.log('list PLAYLIST api resp', response)
      })
      .catch((error) => {
        console.log('list PLAYLIST api resp', error)
      })
  }

  const { dispatch } = useContext(Store)

 

  useEffect(() => {
    const handleWarnning = () => {
      setSubacriptionWarnning(true, dispatch)
    }
    handleWarnning()
  }, [dispatch])

  useEffect(() => {
    listPlayList()
  }, [])


  useEffect(() => {
    if (status === 'Online' && screenState === 'Play') {
      setIsPlaying(true)
    }
  }, [screenInfo, status, screenState])


  const getScreen = () => {
    const getScreen = {
      url: '/getScreen',
      method: 'POST',
      data: JSON.stringify({ id: id })
    }
    axios(getScreen)
      .then(res => {
        console.log('get screen api res', res)
        const { data } = res
        const { media, screenTickerStatus } = data
        setScreenInfo(data)
        setScreenMedia(media)
        setScroll(screenTickerStatus)
      })
      .catch(err => console.log('get screen api error', err))
  }

  useEffect(() => {
    const getScreen = {
      url: '/getScreen',
      method: 'POST',
      data: JSON.stringify({ id: id })
    }
    axios(getScreen)
      .then(res => {
        console.log('get screen api res', res)
        const { data } = res
        const { media, screenTickerStatus } = data
        setScreenInfo(data)
        setScreenMedia(media)
        setScroll(screenTickerStatus)
      })
      .catch(err => console.log('get screen api error', err))
  }, [id])

  console.log("screenMedia", screenMedia)

  const deleteScreen = () => {
    const data = {
      id: id
    }
    const deleteScreen = {
      url: '/deleteScreen',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(deleteScreen)
      .then(res => {
        console.log('deleteScreen api res', res)
        navigate(screens, { state: 'data' })
      })
      .catch(err => console.log('deleteScreen api error', err))
  }

  const addMediaToScreen = (item) => {
    setSelectedMedia([...selectedMedia, item])
    const data = {
      id: screenId,
      media: [...screenMedia, item]
    }
    const addMediaToScreen = {
      url: '/addScreenMedia',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(addMediaToScreen)
      .then(res => {
        console.log('addMediaToScreen api res', res)
      })
      .catch(err => console.log('addMediaToScreen api error', err))
  }

  const handlePlayer = (value) => {
    if (value === 'Play') setIsPlaying(true)
    if (value === 'Stop') setIsPlaying(false)
    const data = {
      screenId: screenId,
      screenState: value
    }
    const startPlayerApi = {
      url: '/screenControl',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(startPlayerApi)
      .then(res => {
        console.log('updateScreen api res', res)
        getScreen()
      })
      .catch(err => console.log('updateScreen api error', err))
  }



  const handleScroll = (value) => {
    const data = {
      screenId: screenId,
      screenTickerStatus: value,
      screenTicker: {
        backgroundColor: scrollBg,
        fontColor: scrollFont,
        text: scrollText
      }
    }
    const addMediaToScreen = {
      url: '/ticketControl',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(addMediaToScreen)
      .then(res => {
        console.log('updateScreen api res', res)
      })
      .catch(err => console.log('updateScreen api error', err))
  }

  const handleScrollInputChange = (e) => {
    const { checked } = e.target
    if (checked) {
      setScroll(true)
      handleScroll(true)
    } else {
      setScroll(false)
      handleScroll(false)
    }
  }

  const ToggleButton = ({ label, handleInputChange, isChecked }) => {
    return (
      <FlexWrapper align='center' justify='space-between' marginRight='20px'>
        <Subtitle marginRight='20px' color={theam_black}> {label} </Subtitle>
        <label className='switch'>
          <input type='checkbox' checked={isChecked} onChange={handleInputChange} />
          <span className='slider round' />
        </label>
      </FlexWrapper>
    )
  }

  const handleMediaRemove = (item) => {
    const newScreens = screenMedia.filter((screen) => screen.id !== item.id)
    setScreenMedia(newScreens)

    const data = {
      id: screenId,
      media: newScreens
    }
    const addMediaToScreen = {
      url: '/addScreenMedia',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(addMediaToScreen)
      .then(res => {
        console.log('addMediaToScreen api res', res)
      })
      .catch(err => console.log('addMediaToScreen api error', err))
  }

  const isOnline = () => {
   return isDeviceActive(screenLiveTime)

  }

  // const [state, setState] = useState({ quotes: initial });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }


  const newList = reorder(
      screenMedia,
      result.source.index,
      result.destination.index
    );
    setScreenMedia(newList);
  }

  const [ screenTitle, setScreenTitle ] = useState(name)
  const [ isEditTitle, setEditTitle ] = useState(false)
  
  const handleNameChange = (e) => {
    setScreenTitle(e.target.value)
  }

  const toggleTitleEdit = () => {
    setEditTitle(!isEditTitle)
  }

  const screenTitleChange = () => {
    const data = {
      id: screenId,
      screenName: screenTitle,
    }
    const startPlayerApi = {
      url: '/updateScreen',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(startPlayerApi)
      .then(res => {
        setEditTitle(false)
      })
      .catch(() => {setScreenTitle(name)
        setEditTitle(false)})
  }
 
  const [showPopup, setShowPopup] = useState(false)
  const [memmryLoader, setMemoryloader] = useState(false)


  const onClearMemory = () => {
    setMemoryloader(true)
    handlePlayer('Stop')
    const data = {
      screenId: screenId,
      screenState: 'Clear Memory'
    }
    const startPlayerApi = {
      url: '/screenControl',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(startPlayerApi)
      .then(res => {
        console.log('clear memory api res', res)
        getScreen()
        setMemoryloader(false)
        closePopup()
      })
      .catch(err => {
         setMemoryloader(false)
         closePopup()
         console.log('clear memory api error', err)})
  }

  const clearMemmory = () => {
    openPopup()
  }


  const closePopup =()=> {
    setShowPopup(false)
  }

  const openPopup =()=> {
    setShowPopup(true)
  }
   

  return (
    <AppWrapper top='3%'>
          {
        showPopup &&
        <WarningPopup
        onCancel={closePopup} onConfirm={onClearMemory}
        heading='Clear Memory' message='Confirm memory clearance? This will delete your downloaded items.' 
        confirmTitle='Confirm' loading={memmryLoader}
        />
      }
      <FlexWrapper align='center' paddingTop='0px'  marginBottom="20px">
       {
        isEditTitle ?
        <InputBox label={'title'} type="text" name={'name'} value={screenTitle} handleInputChange={handleNameChange} onCancel={toggleTitleEdit} onSubmit={screenTitleChange} /> :
        <>
        <Title color={theam_black} size='24px'> {screenTitle}</Title> 
        <i onClick={toggleTitleEdit} className="fa fa-pencil-square-o" style={{  fontSize: '18px' ,  marginLeft: '16px', color: primary_color , cursor: 'pointer' }} ></i>
        </>
       }
        
      </FlexWrapper>
      <FlexWrapper align='flex-start' justify='space-between' paddingTop='0px'>
        <FlexWrapper align='center' height='50px' width='35vw' paddingTop='5px' marginBottom="20px">
          {
            isPlaying
              ? <IconsButton color={red_color} onClick={() => handlePlayer('Stop')} active={isPlaying}>
                <i className="fa-solid fa-stop" ></i>
                Stop
              </IconsButton>
              : <IconsButton color={primary_color} onClick={() => handlePlayer('Play')} active>
                <i className="fa-solid fa-play" ></i>
                Play
              </IconsButton>
          }

          <IconsButtonSmall color={primary_color} disabled={!(isOnline())} onClick={() => handlePlayer('Resync')} >
            <i className="fa-solid fa-rotate" style={{ marginRight: '4px' }} /> Sync Media
          </IconsButtonSmall>
          {
            status &&
            <Caption margin='0px' color={isOnline() ? green_color : theam_black}> {isOnline() ? 'Online' : 'Offline'}  </Caption>
          }
          {
            !(isOnline()) && status
              ? <Subtitle marginLeft='20px' color={light_color}> {screenLiveTime ? `Last active ${dateToFromNowDaily(screenLiveTime)}` : 'Last active long ago'} </Subtitle>
              : null
          }
        </FlexWrapper>

        <FlexWrapper>
          <ToggleButton isChecked={isScroll} handleInputChange={handleScrollInputChange} label='Scroll' />
          <ToggleButton label='Portrait' />
          <ToggleButton label='Repeat' isChecked handleInputChange={() => { }} />
          <PrimaryButton color={red_color} onClick={deleteScreen}> Delete </PrimaryButton>
        </FlexWrapper>
      </FlexWrapper>

      <FlexWrapper>

        <FlexWrapper width='50%' height='76vh' paddingBottom="10vh"
          overflow='scroll' direction='column' marginTop='30px'>
          {isNonEmptyArray(screenMedia) &&
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <MediaListCards playlist={playlist} screenMedia={screenMedia} handleMediaRemove={handleMediaRemove} />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          }

          {
            isEmptyArray(screenMedia) && <EmptyItem />
          }
          {
            isNull(screenMedia) &&
            <FlexWrapper height="50%" width="100%" align='center' justify='center' >
              <Loader />
            </FlexWrapper>
          }
        </FlexWrapper>

        <FlexWrapper justify='flex-end' width='50%' paddingTop='30px'>
          <MediaBar
            {...{
              scrollText, scrollBg, scrollFont, selectedMedia, setSelectedMedia, addMediaToScreen, clearMemmory, 
              updateScreenMedia, handleScroll, handleScrollText, setScrollBg, setScrollFont, screenMedia, setScreenMedia, screenId, screenInfo
            }}
          />
        </FlexWrapper>
      </FlexWrapper>
    </AppWrapper>
  )
}

export default Index
