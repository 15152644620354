import React, { useEffect, useState } from 'react'
import { CardGridWrapper, AppWrapper, FlexWrapper, ScrollWrapper } from '../../components/wrapper/Wrapper.style'
import PlaylistCard from '../../components/PlaylistCard/index'
import Topbar from '../../components/Topbar/index'
import { StyledLink } from '../../components/Link/StyledLink.style'
import axios from 'axios'
import { getUserName } from '../../utils/commonUtils'
import Popup from '../../components/Popup/index'
// import Skelton from '../../components/Skelton/index'
import Loader from '../../components/Loader/index'

const Index = () => {
  const [playlist, setPlaylist] = useState()
  const [showCreatePlaylistPopup, setCreatePlaylistPopup] = useState(false)
  const [newPlaylistData, setNewPlaylistData] = useState('')

  const closeCreatePlaylistPopup = () => { setCreatePlaylistPopup(false) }
  const openCreatePlaylistPopup = () => { setCreatePlaylistPopup(true) }
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setNewPlaylistData({ ...newPlaylistData, [name]: value })
  }

  const listPlayList = () => {
    const listPlaylist = {
      url: '/listPlaylist',
      method: 'POST',
      data: JSON.stringify({ username: getUserName() })
    }
    axios(listPlaylist)
      .then((response) => {
        const { data: { playlist: pl } } = response
        setPlaylist(pl)
        console.log('list PLAYLIST api resp', response)
      })
      .catch((error) => {
        console.log('list PLAYLIST api resp', error)
      })
  }

  useEffect(() => {
    listPlayList()
  }, [])

  const createNewPlaylist = () => {
    if (newPlaylistData && newPlaylistData.name) {
      const data = {
        name: newPlaylistData.name,
        username: getUserName(),
        duration: 0,
        type: 'playlist'
      }

      const createPlayList = {
        url: '/createPlaylist',
        method: 'POST',
        data: JSON.stringify(data)
      }
      axios(createPlayList)
        .then((response) => {
          console.log('createPlayList api resp', response)
          const { data } = response
          console.log('datasdasd', data)
          listPlayList()
        })
        .catch((error) => {
          console.log('link screen api err', error)
        })
      closeCreatePlaylistPopup()
    }
  }

  let sortedPlaylist = null
  if (playlist && playlist.length > 1) {
    sortedPlaylist = playlist.sort(function (x, y) {
      const firstDate = new Date(x.createdAt)
      const SecondDate = new Date(y.createdAt)

      console.log("first data playlist", firstDate)


      if (firstDate < SecondDate) return 1
      if (firstDate > SecondDate) return -1
      return 0
    })
  }


  const [searchTerm, setSearchTerm] = useState('')
  const searchOnChange = (e) => {
    setSearchTerm(e.target.value)
  }

  if (searchTerm.length > 0) {
    sortedPlaylist = playlist && playlist.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  
 

  return (
    <AppWrapper>
      {
        showCreatePlaylistPopup &&
          <Popup
            closeCreateScreenPopup={closeCreatePlaylistPopup}
            create={createNewPlaylist}
            handleInputChange={handleInputChange}
            data={newPlaylistData}
            lablelOne='Playlist Name'
            nameOne='name'
            heading='Create Playlist'
          />
      }
      <Topbar placeholder='Search playlist' 
      searchOnChange={searchOnChange}
      title='Playlist' buttonOne='Create Playlist' buttonOneClick={openCreatePlaylistPopup} />
      {
        (playlist && playlist.length > 0) ? 
        <ScrollWrapper>
        <CardGridWrapper>
        {
          sortedPlaylist && sortedPlaylist.map((item, i) =>
          <StyledLink to={`/playlist/manage/${item.id}/${item.name}`} key={i}>
            <PlaylistCard playlist={item.name} count={item.mediaCount} duration={item.duration} />
          </StyledLink>
        )
       }
      </CardGridWrapper>
      </ScrollWrapper>
      :
      <FlexWrapper height="500px" width="100%"  align='center' justify='center' >
      <Loader />
      </FlexWrapper>
      }
    </AppWrapper>
  )
}

export default Index
