import styled from 'styled-components'

export const Upload = styled.div`
position: absolute;
width: 20vw;
top: 80vh;
left: 75vw; 
display: flex;
justify-content: center;
align-items: center;
background-color: #37B878;
z-index: 999999;
color: #fff;
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`
export const ProgressTrack = styled.div`
width: 100px;
height: 5px;
background-color:#fff ;
border-radius: 8px;
position: relative;
`
export const ProgressBar = styled.div`
position: absolute;
left: 0px;
top: 0px;
width: ${props => props.width};
height: 5px;
background-color: #C1C2C2;
border-radius: 8px;
`
// #5A91FF