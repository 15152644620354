import React, { useEffect } from 'react'
import Topbar from '../../components/Topbar/index'
import { AppWrapper, FlexWrapper } from '../../components/wrapper/Wrapper.style'
import './style.css'
import Popup from './popup'
import { useState } from 'react'
import { cells, days, getColor, getEndTimeFromCellId, getStartTimeFromCellId, time } from './utils'
import Schedules from './schedules'
import { getUserName } from '../../utils/commonUtils'
import axios from 'axios'

const Index = () => {  
  const [showSchedulerPopup, setShowSchedulerPopup] = useState(false)
  const [selectedCalnder, setSelectedCalnder] = useState(null)
  const [schedules, setSchedules] = useState()
  const [currentSchedule, setcurrentSchedule] = useState({})
  const [error, setError] = useState(null)

  

  const cellClickHandle = (cellID) => {
    setSelectedCalnder(cellID)
    setcurrentSchedule({ 
      ...currentSchedule,
      start: getStartTimeFromCellId(cellID),
      end: getEndTimeFromCellId(cellID)
    })
    setShowSchedulerPopup(true)
  }


  useEffect(() => {
    const listScreens = {
      url: '/listScheduler',
      method: 'POST',
      data: JSON.stringify({ username: getUserName() })
    }
    axios(listScreens)
      .then((response) => {
        const { data: {scheduler} } = response
        setSchedules(scheduler)
      })
      .catch((error) => {
        console.log('list scheduler api resp', error)
      })
  }, [])


  const handleInputChange = (e) => {
    const { name, value } = e.target
    if(name === 'start' || name === 'end' ){
      setError(null)
    }
    setcurrentSchedule({ ...currentSchedule, [name]: value })
  }

  const createNewScheduler = () => {
    if( parseInt(currentSchedule.start) <= parseInt(currentSchedule.end)){
      const schedulerInfo = { 
        ...currentSchedule, 
        color: getColor(),
        playlist: '',
        cellID: selectedCalnder,
        username: getUserName(),
      };
      

      const createSchedulerApi = {
        url: '/createScheduler',
        method: 'POST',
        data: JSON.stringify(schedulerInfo)
      }
      axios(createSchedulerApi)
        .then(() => {
      setSchedules([...schedules, schedulerInfo])
       
        })
        .catch((error) => {
          console.log('list scheduler api resp', error)
        })

        setShowSchedulerPopup(false);
        setcurrentSchedule({})
    } else {
      setError('The End time must be greater than the Start time.')
    }
  }

  return (
    <AppWrapper>
      {
        showSchedulerPopup &&
        <Popup
          closeCreateScreenPopup={() => setShowSchedulerPopup(false)}
          selectedCalnder={selectedCalnder}
          handleInputChange={handleInputChange}
          create={createNewScheduler}
          currentSchedule={currentSchedule}
          error={error}
        />
      }
      <Topbar showSearch={false} placeholder='Search media' title='Scheduler' />

      <FlexWrapper marginTop='10px'>
        <div className="time-container">
          {
            time.map(element => {
              return (<div className="time ">{element}</div>)
            })
          }
        </div>
        <FlexWrapper direction='column' marginTop='40px'>
          <div className="days-container">
            {
              days.map(element => {
                return (<div className="days ">{element}</div>)
              })
            }
          </div>
          <div className="calander-container">

            {
              schedules && schedules.map((item) => {
                return (
                  <Schedules item={item} />
                )
              })
            }
            {
              cells.map(element => {
                return (<div className="item " onClick={() => cellClickHandle(element)} >{ }</div>)
              })
            }
          </div>
        </FlexWrapper>
      </FlexWrapper>
    </AppWrapper>
  )
}

export default Index
