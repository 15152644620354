import React from 'react'
import { secondary_color, primary_color } from '../../const/color_theam'
import { SmallButton } from '../button/Button.style'
import { Subtitle } from '../typography/Subtitle.style'
import { Title } from '../typography/Title.style'
import { FlexWrapper, SmallImageWrapper } from '../wrapper/Wrapper.style'

const index = ({ item, selectMedia, size }) => {
  return (
    <FlexWrapper width='470px' align='center' marginBottom="20px"  >
      {
        item.type === 'Image' &&
        <SmallImageWrapper title={item.mediaName} image={item.thumbnail} size={size} />
      }
      {
        item.type === 'Video' && <img title={item.mediaName} src={require(`../../assets/images/dummy/video.png`)} alt="media" width='60px' style={{ borderRadius: "6px" }} />
      }
      {
        item.type === 'playlist' && <img src={require(`../../assets/images/dummy/playlist-icon.png`)} alt="media" width='36px' style={{ borderRadius: "6px" }} />
      }
      {
        item.start && <img src={require(`../../assets/images/dummy/calendar-icon.png`)} alt="media" width='30px' style={{ borderRadius: "6px" }} />
      }
      <FlexWrapper width="180px" marginLeft="20px" direction="column" >
        <Title size="12px" marginRight='20px' color={secondary_color}> {item.name ? item.name.substring(0, 18) : (item.mediaName && item.mediaName.substring(0, 20).split('-').join(" "))} </Title>
        {
          item.type !== 'playlist' &&
          <Subtitle marginTop='5px' color={secondary_color}> {item.type} </Subtitle>
        }
      </FlexWrapper>
      <FlexWrapper width="50px" marginLeft="10px" marginRight='20px' >
        {
          item.type === 'playlist' ? <Subtitle color={secondary_color}> {item.mediaCount} Items </Subtitle> :
            <Subtitle color={secondary_color}> {item.duration} Sec </Subtitle>
        }
      </FlexWrapper>
      <SmallButton color={primary_color} active onClick={() => { selectMedia(item) }}> Add </SmallButton>
    </FlexWrapper>
  )
}

export default index
