import styled from 'styled-components'


export const Title = styled.p`
user-select:none;
color: ${props => props.color};
font-size: ${ (props) => props.size || '16' };
font-family: 'Roboto', sans-serif;
font-weight: 500;
margin: 0;
margin-bottom: ${ (props) => props.margin || '0px' };
margin-top: ${ (props) => props.marginTop || '0px' };
`

export const TitleChara = styled.p`
color: ${props => props.color};
font-size: ${ (props) => props.size || '16' };;
font-family: 'Roboto', sans-serif;
font-weight: 500;
margin: 0;
margin-top: 0px;
text-transform: capitalize;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`

export const TitleOne = styled.p`
color: ${props => props.color};
font-size: 22px;
font-family: 'Roboto', sans-serif;
font-weight: 500;
margin: 0;
margin-top: 0px;
align-self: center;
`

export const TitleThree = styled.p`
color: ${props => props.color};
font-size: 25px;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
margin: 0;
align-items: center;
margin-top: 30px;
`