import styled from 'styled-components'


export const MediaBarWrapper = styled.div`
height: 80vh;
width: 520px;
border-radius: 8px;
display: flex;
flex-direction: column;
padding: 0px 40px;
box-sizing: border-box;
overflow-y: scroll;
background-color: ${(props) => props.bgColor || ''};
padding-bottom: 5vh;
::-webkit-scrollbar {
    display: none;
}
`


