import React from 'react'
import { primary_color, red_color, theam_black } from '../../const/color_theam'
import { PrimaryButton } from '../button/Button.style'
import { TitleOne } from '../typography/Title.style'
import { Popup, PopupWrapper } from './Popup.style'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import { Messege } from '../typography/Caption.style'

const Index = ({ onCancel, onConfirm, heading, message , confirmTitle='Okey', loading = false }) => {
  return (
    <>
      <Popup onClick={onCancel} />
      <PopupWrapper >
        <FlexWrapper align='center' justify='center' marginBottom='30px'>
          <TitleOne color={theam_black}  >{heading}</TitleOne>
        </FlexWrapper>
        {
          message && 
       <Messege>{message}</Messege>
        }
        <FlexWrapper align='center' justify='flex-end' marginTop='10px'>
          <PrimaryButton marginTop='20px' marginRight="25px" color='#F5F5FB' fontcolor='#060609' onClick={onCancel} >Cancel</PrimaryButton>
          <PrimaryButton marginTop='20px' marginRight="0px" color={ confirmTitle === 'Delete' ? red_color : primary_color} onClick={onConfirm} >
          { loading ? <div className='loader-small' /> :  confirmTitle}
            </PrimaryButton>
        </FlexWrapper>
      </PopupWrapper>
    </>
  )
}

export default Index