export const background_color = '#F5F5FB';
export const primary_color = '#5e81f4';
export const secondary_color = '#9393bf';
export const tertiary = '#F5F5F5';
export const green_color ='#37B878'
export const red_color = '#d12413'
export const light_color ='#C1C2C2'
export const light_background ='#FEFEFE'
export const dark ='#060609'


export const theam_blue ='#4d4cac'
export const theam_violet ='#9698d6'
export const theam_red ='#ff808b'
export const theam_black ='#060609'
export const theam_white ='#FEFEFE'
export const theam_green = '#3CB371'







// export const background_color = '#FFFFFF';
// export const primary_color = '#4C7EEE';
// export const secondary_color = '#777777';
// export const tertiary = '#F5F5F5';
// export const green_color ='#37B878'
// export const red_color = '#d12413'
// export const light_color ='#C1C2C2'
// export const light_background ='#FAFAFA'