import styled from 'styled-components'
import { theam_black} from '../../const/color_theam'


export const Input = styled.input`
width: auto;
height: 40px;
border-radius: 6px;
border: 1px solid ${theam_black};
margin-top: 15px;
padding-left: 15px;
`