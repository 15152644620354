import React from 'react'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import { Popup, MediaPopupWrapper } from './Popup.style'


const Index = ({ closePopup, selectedMedia }) => {
    const { url } = selectedMedia
    return (
    <>
            <Popup onClick={closePopup} />
            <MediaPopupWrapper width='650px' color='#fff' padding='20px' >
                <FlexWrapper align='center' justify='center' direction="column">
                    <video width="100%" autoPlay>
                        <source src={url} type="video/mp4" />
                    </video>
                        </FlexWrapper>
                    </MediaPopupWrapper>
                </>
                )
}

                export default Index