import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { StoreProvider } from './store/store'
import axios from 'axios'
axios.defaults.baseURL = 'https://ytdbtny939.execute-api.us-east-1.amazonaws.com/dev/'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StoreProvider>
    <App />
  </StoreProvider>
)

reportWebVitals()
