import React, { createContext, useReducer } from 'react';
import { reducer } from "../reducers";
import { playlist , screenMedia, userData  } from './data';

const initialState = {
    screens: [],
    playlist,
    screenMedia,
    userData,
    showSubscriptionWarnning: true
};

export const Store = createContext(initialState);

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>;
};