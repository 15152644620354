import styled from 'styled-components'
import { tertiary, light_background } from '../../const/color_theam'


export const Card = styled.div`
width:  ${props => props.width};
height: 55px;
padding: ${ props => props.padding};
background-color:  ${  props => props.color ?? light_background};
border-radius: 10px;
display: flex;
align-items: center;
// box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
transition: 0.4s;

&:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

`
export const MediaListCard = styled.div`
width:  ${props => props.width};
height: 75px;
background-color: ${tertiary};
border-radius: 8px;
display: flex;
align-items: center;
margin-bottom: 22px;
background-color: ${light_background};
padding-top:12px;
padding-bottom:12px;
`
export const MediaCardWraper = styled.div`
padding: 6px 8px;
width:220px;
background-color: ${tertiary};
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`
export const StatusIconWrapper = styled.div`
background-color: #ffffff;
width: 40px;
height: 40px;
border-radius: 4px;
margin-right: 8px;
position: relative;
opacity:0.2
`
export const InfoWrapper = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
padding: ${ props => props.padding || '30px'};
width:  ${ props => props.width || '45%'};
`
