import styled from 'styled-components'


export const Input = styled.input`
width: 300px;
height: 40px;
border-radius: 6px;
border: 1px solid #000;
margin-top: 15px;
padding-left: 15px;
background-color: #F5F5F5;
border: ${ (props) => props.border ? '1px solid #000' : 'none'  };
`