import styled from 'styled-components'

export const Popup = styled.div`
position: absolute;
height: 100vh;
width: 100vw;
top: 0;
left: 0; 
display: flex;
justify-content: center;
align-items: center;
background-color: #1E2D4D;
opacity: .6;
z-index: 10;
`

export const PopupWrapper = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
height: 500px;
width: 50vw;
background-color: #fff;
z-index: 9999;
border-radius: 8px;
display: flex;
flex-direction: column;
padding: 80px 70px;
box-sizing: border-box;
overflow-y: scroll;
::-webkit-scrollbar {
    display: none;
}
`

