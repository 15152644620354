import styled from 'styled-components'


export const Caption = styled.p`
color: ${props => props.color};
font-size: 12px;
font-family: 'Open Sans', sans-serif;
font-weight: 500;
margin: 0;
align-items: center;
margin-left: ${props => props.margin};
margin-top: ${props => props.marginTop};
margin-bottom: ${props => props.marginBottom};
`


export const Messege = styled.p`
color: ${props => props.color};
font-size: 12px;
font-family: 'Open Sans', sans-serif;
font-weight: 600;
margin: 0;
align-items: center;
margin-left: ${props => props.margin};
margin-top: ${props => props.marginTop};
text-align: center
`