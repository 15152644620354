import React, { useEffect, useState } from 'react'
import { DragaAndDropWrapper, FlexWrapper, MediaListGridWrapper, StaticWrapper } from '../wrapper/Wrapper.style'
import { MediaBarWrapper } from './MediaBarWrapper.style'
import MediaItem from './mediaItem'
import axios from 'axios'
import { light_background, primary_color } from '../../const/color_theam'
import { getUserName } from '../../utils/commonUtils'
import UploadIcon from '../../assets/icons/uploadicon'
import { TitleThree } from '../typography/Title.style'
import { NavbarButtonSmall } from '../header/NavbarButton.style'
import { FileUploader } from "react-drag-drop-files";
import Scroll from '../../components/scroll/index'
import Info from '../../components/info/index'
const fileTypes = ["JPG", "PNG", "GIF", 'mp4'];

const Index = ({ handleScroll, selectedMedia, updateScreenMedia,
  addMediaToScreen, handleScrollText, setScrollBg, setScrollFont, clearMemmory = () => {}, screenInfo,
  scrollText, scrollBg, scrollFont, screenMedia, setScreenMedia, screenId, isPlaylist = false,
}) => {
  const [media, setMedia] = useState(null);
  const [schedules, setScheduler] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const selectMedia = (item) => {
    updateScreenMedia(item)
    addMediaToScreen(item)
  }

  const listPlayList = () => {
    const listPlaylist = {
      url: 'https://ytdbtny939.execute-api.us-east-1.amazonaws.com/dev/listPlaylist',
      method: 'POST',
      data: JSON.stringify({ "username": getUserName() })
    }
    axios(listPlaylist)
      .then((response) => {
        const { data: { playlist: pl } } = response
        setPlaylist(pl)
        console.log("list PLAYLIST api resp", response)
      })
      .catch((error) => {
        console.log("list PLAYLIST api resp", error)
      })
  }

  const listMedia = () => {
    const getScreenMediaList = {
      url: 'https://ytdbtny939.execute-api.us-east-1.amazonaws.com/dev/listMedias',
      method: 'POST',
      data: JSON.stringify({ "username": getUserName() })
    }

    axios(getScreenMediaList)
      .then(res => {
        console.log("media list api res", res.data)
        const { media } = res.data
        setMedia(media)
      })
      .catch((err => console.log("media list api error", err)))
  }


  const listScheduler = () => {
    const getSchedulerList = {
      url: 'https://ytdbtny939.execute-api.us-east-1.amazonaws.com/dev/listScheduler',
      method: 'POST',
      data: JSON.stringify({ "username": getUserName() })
    }

    axios(getSchedulerList)
      .then(res => {
        console.log("schedules list api res", res.data)
        const { scheduler } = res.data
        setScheduler(scheduler)
      })
      .catch((err => console.log("schedules list api error", err)))
  }

  useEffect(() => {
    listMedia();
    listPlayList();
    listScheduler()
  }, [])


  const [mediaSource, setMediaSource] = useState("media")

  const selectFromMedia = () => {
    setMediaSource('media')
  }
  const selectFromPlaylist = () => {
    setMediaSource('playlist')
  }
  const selectScroll = () => {
    setMediaSource('scroll')
  }

  const selectInfo = () => {
    setMediaSource('info')
  }
  // const selectScheduler = () => {
  //   setMediaSource('scheduler')

  // }

  const addMediaToTheScreen = (newMedia) => {
    const data = {
      id: screenId,
      media: [...screenMedia, newMedia]
    }
    const addMediaToScreen = {
      url: '/addScreenMedia',
      method: 'POST',
      data: JSON.stringify(data)
    }

    axios(addMediaToScreen)
      .then(res => {
        console.log('addMediaToScreen api res', res)
      })
      .catch(err => console.log('addMediaToScreen api error', err))
  }


  const handleImage = (file) => {
    for (let i = 0; i < file.length; i++) {
      const options = {
        headers: {
          'Content-Type': file[i].type
        },
      }
      const token = localStorage.getItem('token')
      console.log('file[i]....', file[i])
      const pre_upload = {
        name: file[i].name,
        type: file[i].type,
        token: token
      }

      const newImage = {
        createdDate: Date.now(),
        thumbnail: URL.createObjectURL(file[i]),
        url: URL.createObjectURL(file[i]) ,
        mediaName: file[i].name,
        type: file[i].type === 'video/mp4' ? 'Video' : 'Image',
        duration: 5,
        view: 'cover',
        username: getUserName()
      }

      console.log("newImage", newImage)
      const newArr = [...screenMedia, newImage ]
      setScreenMedia(newArr)


      const reader = new FileReader()
      const fd = new FormData()
      fd.append('file', file[i])
      reader.readAsArrayBuffer(file[i])

      axios.post('/getSignedUrl', pre_upload).then(res => {
        const upload_file = new Blob([reader.result], { type: file[i].type })
        axios.put(res.data.uploadURL, upload_file, options).then(res => {
          const mediaData = {
            mediaName: file[i].name,
            type: file[i].type === 'video/mp4' ? 'Video' : 'Image',
            duration: 5,
            view: 'cover',
            url: 'https://viewbox-media.s3.amazonaws.com/media/' + file[i].name,
            username: getUserName()
          }
          const createMedia = {
            url: '/createMedia',
            method: 'POST',
            data: JSON.stringify(mediaData)
          }
          axios(createMedia)
            .then((res) => {
               const {data: newMedia} = res;
              addMediaToTheScreen(newMedia)
            }) 
            .catch((error) => {
              console.log('create media error', error)
            })
        })
      })
    }
  }


  const isActive = (item) => {
    return item === mediaSource
  }

  let sortedMedia = media && media.sort((a,b) => (a.mediaName.toLowerCase() > b.mediaName.toLowerCase()) ? 1 : ((b.mediaName.toLowerCase() > a.mediaName.toLowerCase()) ? -1 : 0))

  return (
    <MediaBarWrapper bgColor={light_background}>
      <StaticWrapper bgColor={light_background}>

       {
          !isPlaylist &&
          <FlexWrapper align='center' justify='flex-start' marginBottom="40px">
          <NavbarButtonSmall color={isActive("media") ? primary_color : null} onClick={selectFromMedia}>Media</NavbarButtonSmall>
          <NavbarButtonSmall color={isActive("playlist") ? primary_color : null} onClick={selectFromPlaylist}>Playlist</NavbarButtonSmall>
          {/* <NavbarButtonSmall color={isActive("scheduler") ? primary_color : null} onClick={selectScheduler}>Scheduler</NavbarButtonSmall> */}
          <NavbarButtonSmall color={isActive("scroll") ? primary_color : null} onClick={selectScroll}>Scroll</NavbarButtonSmall>
          <NavbarButtonSmall color={isActive("info") ? primary_color : null} onClick={selectInfo}>Info</NavbarButtonSmall>
        </FlexWrapper>
      }
      </StaticWrapper>


      {
        mediaSource === 'storage' &&
        (
          <FlexWrapper align='center' justify='center' marginBottom="40px" direction="column" height="100%" >
            <>
              <FileUploader multiple handleChange={handleImage} name="file" types={fileTypes} >
                <DragaAndDropWrapper h="210px" w="350px">
                  <UploadIcon />
                  <TitleThree >Drag file here or Browse</TitleThree>
                </DragaAndDropWrapper>
              </FileUploader>
            </>
          </FlexWrapper>
        )
      }
      {
        mediaSource === 'media' &&
        (
          <>
            <MediaListGridWrapper count={1}>
              {
                sortedMedia &&  sortedMedia.map((item, i) =>
                  <MediaItem key={i} item={item} selectMedia={selectMedia} selectedMedia={selectedMedia} size={'60px'} />)
              }
            </MediaListGridWrapper>
          </>
        )
      }
      {
         mediaSource === 'scroll' &&
        <Scroll
          scrollText={scrollText} scrollBg={scrollBg} scrollFont={scrollFont}
          handleScroll={handleScroll} handleScrollText={handleScrollText} setScrollBg={setScrollBg} setScrollFont={setScrollFont} />
      }


      {
         mediaSource === 'info' && screenInfo &&
        <Info
          scrollText={scrollText} scrollBg={scrollBg} scrollFont={scrollFont} clearMemmory={clearMemmory} screenInfo={screenInfo}
          handleScroll={handleScroll} handleScrollText={handleScrollText} setScrollBg={setScrollBg} setScrollFont={setScrollFont} />
      }
      {
        mediaSource === 'playlist' &&
        (
          <>
            <MediaListGridWrapper count={1}>
              {
                playlist && playlist.map((item, i) =>
                  <MediaItem key={i} item={item} selectMedia={selectMedia} selectedMedia={selectedMedia} size={'60px'} />)
              }
            </MediaListGridWrapper>
          </>
        )
      }

      {
        mediaSource === 'scheduler' &&
        (
          <>
            <MediaListGridWrapper count={1}>
              {
                schedules && schedules.map((item, i) =>
                  <MediaItem key={i} item={item} selectMedia={selectMedia} selectedMedia={selectedMedia} size={'60px'} />)
              }
            </MediaListGridWrapper>
          </>
        )
      }
    </MediaBarWrapper>
  )
}

export default Index