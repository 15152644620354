import styled from 'styled-components'


export const Subtitle = styled.p`
user-select:none;
color: ${props => props.color};
font-size: 12px;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
margin: 0;
margin-right: ${props => props.marginRight || '0px' };
margin-left: ${props => props.marginLeft || '0px' };
margin-top: ${props => props.marginTop || '0px' };
// text-transform: capitalize;
`