import { getUserName } from "../utils/commonUtils"

export const linkScreen = '/linkScreen'
// export const listScreens = '/listScreens'

export const createScreen = {
  url: '/createScreen',
  method: 'POST',
  data: JSON.stringify({ screenName: '', status: 'Live', username: getUserName() })
}
export const getScreenMediaList = {
  url: '/listMedia',
  method: 'GET'
}

export const listScreens = {
  url: '/listScreens',
  method: 'POST',
  data: JSON.stringify({ username: getUserName() })
}
