import styled from 'styled-components'
import {primary_color} from '../../const/color_theam'


export const PrimaryButton = styled.button`
border: none;
font-family: 'Roboto', sans-serif;
text-decoration: none;
padding: 14px 26px;
margin: 0;
background-color: ${ (props) => props.color || 'transparent' };
font-size: 14px;
font-family: 'Roboto', sans-serif;
font-weight: 400;
letter-spacing: 1px;
color: ${ (props) => props.fontcolor || '#fff' };
border-radius: 10px;
cursor: pointer;
margin-right: ${ (props) => props.marginRight || '30px' };
margin-left: ${ (props) => props.marginLeft || '0px' };
margin-top: ${ (props) => props.marginTop || '0' };
transition: 0.4s;

&:hover {
  box-shadow: ${ (props) => `${props.color} 0px 4px 14px` || 'rgba(0, 0, 0, 0.35) 0px 5px 15px;' };
   }
`
export const LoginButton = styled.button`
border: none;
font-family: 'Roboto', sans-serif;
text-decoration: none;
/* padding: 10px 22px ; */
margin: 0;
background-color: ${ (props) => props.color || 'transparent' };
font-size: 14px;
font-family: 'Roboto', sans-serif;
font-weight: 500;
color: #fff;
border-radius: 6px;
height: 36px;
width: 80px;
justify-content: center;
cursor: pointer;
display: flex;
align-items: center;
`
export const SecondaryButton = styled.button`
/* padding: 6px 12px ; */
font-size: 14px;
color: ${ (props) => props.color || '#fff' };
cursor: pointer;
/* margin-right: 12px ; */
border: none;
background-color: transparent;

&:hover {
  color: ${ (props) => props.hovercolor || '#fff' };
}
`

export const SmallButton = styled.button`
border:  ${ (props) =>   `1px solid ${props.color}`  || '1px solid'  };
font-family: 'Roboto', sans-serif;
text-decoration: none;
padding: 7px 16px ;
font-weight: 400;
margin: 0;
font-size: 16px;
font-family: 'Roboto', sans-serif;
font-weight: 400;
color: ${ (props) => props.active ?  '#fff' : props.color  };
border-radius: 6px;
cursor: pointer;
margin-right: ${ (props) => props.marginRight || '20px' };
background-color: ${ (props) =>  props.active ?  props.color : 'transparent' };

&:hover {
  /* background-color: ${ (props) => props.color || 'transparent' };
  color: #FFF; */
  opacity: .7;
}
`

export const SkipButton = styled.button`
font-family: 'Roboto', sans-serif;
text-decoration: none;
margin: 0;
font-size: 14px;
font-family: 'Roboto', sans-serif;
font-weight: 400;
color: #fff;
border-radius: 6px;
cursor: pointer;
background-color: transparent;
border: 1px solid #fff;
padding: 6px 10px;
margin-top: 30px;

&:hover {
  color: rgb(77, 76, 172);
  background-color: #fff;  
}
`


export const IconsButton = styled.button`
border:  ${ (props) =>   `1px solid ${props.color}`  || '1px solid'  };
font-family: 'Roboto', sans-serif;
text-decoration: none;
font-weight: 400;
margin: 0;
font-size: 16px;
font-family: 'Roboto', sans-serif;
font-weight: 400;
color: ${ (props) => props.active ?  '#fff' : props.color  };
border-radius: 6px;
background-color: ${ (props) =>  props.active ?  props.color : 'transparent' };
display: flex;
justify-content: space-between;
align-items: center;
width: 90px;
height: 40px;
padding: 0px 18px;
margin-right: 20px;
opacity: ${ (props) => props.disabled ? .7 : 1  };
cursor: ${ (props) => props.disabled ? 'not-allowed ' : 'pointer'  };

&:hover {
  opacity: .7;
}
`

export const IconsButtonSmall = styled.button`
font-family: 'Roboto', sans-serif;
text-decoration: none;
font-size: 12px;
font-weight: 400;
margin: 0;
font-family: 'Roboto', sans-serif;
font-weight: 400;
color: ${ (props) => props.active ?  '#fff' : props.color  };
border-radius: 6px;
background-color: ${ (props) =>  props.active ?  props.color : 'transparent' };
width: auto;
height: 30px;
margin-right: 16px;
opacity: ${ (props) => props.disabled ? .7 : 1  };
cursor: ${ (props) => props.disabled ? 'not-allowed ' : 'pointer'  };
border: none;

&:hover {
opacity: 0.7
}
`



export const UserButton = styled.button`
border: none;
font-family: 'Roboto', sans-serif;
text-decoration: none;
padding: 10px;
margin: 0;
font-size: 12px;
font-family: 'Roboto', sans-serif;
font-weight: 400;
letter-spacing: 1px;
color: #fff;
border-radius: 50%;
cursor: pointer;
transition: 0.4s;
background-color: ${primary_color};

&:hover {
  box-shadow: ${ (props) => `${props.color} 0px 4px 14px` || 'rgba(0, 0, 0, 0.35) 0px 5px 15px;' };
   }
`

export const  ButtonWrapper  = styled.div`
position: absolute;
bottom: 30px;
left: 30px; 
z-index: 5;
display: flex;
flex-direction: column;
justify-content: flex-start;
background-color: transparent;
`


