import React from 'react'
import { Input } from './Input.style'
import { PrimaryButton } from '../button/Button.style'
import { primary_color } from '../../const/color_theam'
import { FlexWrapper } from '../wrapper/Wrapper.style'


const index = ({value, handleInputChange, type="text", name, border = false , placeholder='', onSubmit, onCancel }) => {
  return (
    <FlexWrapper height='50px' align='center' >
   <Input border={border} type={type} value={ value } name={name} onChange={ handleInputChange } placeholder={placeholder}  />
   <PrimaryButton marginLeft='10px' marginRight='0px' color={primary_color} onClick={onSubmit} >Submit</PrimaryButton>
   <PrimaryButton marginLeft='10px' marginRight='0px' color='#fff' fontcolor='#060609' onClick={onCancel} >Cancel</PrimaryButton>
    </FlexWrapper>
  )
}

export default index