import React from 'react'
import { primary_color, red_color , theam_black} from '../../const/color_theam'
import { PrimaryButton } from '../../components/button/Button.style'
import { TitleOne } from '../../components/typography/Title.style'
import { Popup, PopupWrapper } from './Popup.style'
import TimeBox from './timeInput'
import { FlexWrapper } from '../../components/wrapper/Wrapper.style'
import { Label } from '../../components/typography/Label.style'
import InputBox from '../../components/InputBox/index'
import { Subtitle } from '../../components/typography/Subtitle.style'

const Index = ({closeCreateScreenPopup, create, error, handleInputChange, currentSchedule}) => {

  return (
    <>
    <Popup onClick={closeCreateScreenPopup} />
    <PopupWrapper >
    <FlexWrapper align='center' justify='center' marginBottom='30px'>
    <TitleOne color={theam_black}  > Create Scheduler </TitleOne>
    </FlexWrapper>
    <InputBox label='Scheduler Name' name='name'  handleInputChange={handleInputChange} />

    <TimeBox value={currentSchedule.start} name='start' label='Start Time' handleInputChange={handleInputChange} />
    <TimeBox value={currentSchedule.end} name='end' label='End Time' handleInputChange={handleInputChange}  />
    <Label color={theam_black} > Selecet Playlist </Label>
    <select name="cars" id="cars" style={{marginTop:'20px', height: '40px', padding: '10px',marginBottom:'20px'}} >
    <option value="Playlist 1">Playlist 1</option>
    <option value="Playlist 2">Playlist 2</option>
    <option value="Playlist 3">Playlist 3</option>
    <option value="Playlist 4">Playlist 4</option>
  </select>

  {
    error &&
    <Subtitle color={red_color}> {error} </Subtitle>
  }

    <FlexWrapper align='center' justify='flex-end' marginTop='10px'>
    <PrimaryButton marginTop='20px' marginRight="25px"  color='#F5F5FB' fontcolor='#060609' onClick={closeCreateScreenPopup} >Cancel</PrimaryButton>
    <PrimaryButton marginTop='20px' marginRight="0px"  color={primary_color} onClick={create} >Create</PrimaryButton>
    </FlexWrapper>
    </PopupWrapper>
  </>
  )
}

export default Index