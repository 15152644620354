import styled from 'styled-components'

export const Label = styled.p`
color: ${props => props.color};
font-size: 12px;
font-family: 'Roboto', sans-serif;
font-weight: 500;
margin: 0;
margin-top: 0px;
margin-top: 20px; 
`