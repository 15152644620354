import React from 'react'
import './style.css';

const ScreenLayout = () => {
  return (
    <>
        <div className='text-wrapper' >
        <p className='heading' > Welcome to the world of Digital signage </p>
        <p className='discriptions' > People use viewbox to enhance their business. </p>
        <p className='discriptions' > We are helping people to reduce the distance between signage to digital signage. </p>
        </div> 
    </>
  )
}

export default ScreenLayout