import React from 'react'
import { light_background } from '../../const/color_theam'
import { Messege } from '../typography/Caption.style'
import { Title } from '../typography/Title.style'
import { FlexWrapper } from '../wrapper/Wrapper.style'

const Index = ({ message }) => {
    return (
        <FlexWrapper  bgColor={light_background} direction='column' height="260px" justify='center' align='center' 
        paddingRight='20%' paddingLeft='20%'
        >   
            <img src={require(`../../assets/images/empty.png`)} width="70px" style={{marginBottom:'15px'}} alt='' />
            <Title margin='10px'> No data found. </Title>
            <Messege>There are currently no media files available.
                Please add a playlist or media from the list.</Messege>
        </FlexWrapper>
    )
}

export default Index
