import React from 'react'
import { Card, InfoWrapper, StatusIconWrapper } from '../Card/Card.style'
import { Title, TitleChara } from '../typography/Title.style'
import { Subtitle } from '../typography/Subtitle.style'
import { theam_black, theam_violet, theam_white } from '../../const/color_theam'
import { FlexWrapper } from '../wrapper/Wrapper.style'

const ScreenStatus = ({playlist}) => {
  return (
    <StatusIconWrapper>
    <TitleChara color={theam_black} size='16px'>  { playlist.charAt(0) }  </TitleChara>
    </StatusIconWrapper>
  )
}

const ScreenInfo = ({playlist,count,duration}) => {
  return (
    <InfoWrapper padding='6px' width="80%" >  
    <Title color={ theam_white} size='16px'>  {playlist.toLowerCase()}  </Title>
    <FlexWrapper align='center' justify='center' paddingTop="4px"> 
    <Subtitle color={theam_white}>   {count} Items   </Subtitle>
    {/* <Subtitle color={theam_white} marginLeft='10px'>  {duration} Minutes Play  </Subtitle */}
    </FlexWrapper>
   </InfoWrapper>
  )
}


const index = ({playlist , count, duration}) => {
  return (
    <Card padding='10px 20px' width='200px' color={theam_violet} >
      <ScreenStatus playlist={playlist} ></ScreenStatus>
      <ScreenInfo 
       {...{
        playlist,count,duration
       }}
      ></ScreenInfo>
    </Card>
  )
}

export default index