import React from 'react'
import { MediaListCard, InfoWrapper } from '../Card/Card.style'
import { Title } from '../typography/Title.style'
import { Subtitle } from '../typography/Subtitle.style'
import {   primary_color, red_color, secondary_color } from '../../const/color_theam'
import { ExtraSmallImageWrapper, FlexWrapper, MediaIconWrapper } from '../wrapper/Wrapper.style'
import { SecondaryButton } from '../button/Button.style'

const MediaIcon = ({type, thumbnail, mediaName}) => {
  return (
    <MediaIconWrapper>
      {
        type === 'Image' &&
        <ExtraSmallImageWrapper title={mediaName} image={thumbnail}   /> 
      }
      {
        type === 'Video' &&  <img itle={mediaName}  src={  require(`../../assets/images/dummy/video.png`)} alt="media" width='60px'  style={{ userSelect:'none', borderRadius:"6px" }}   />
      }
      {
        type === 'playlist' &&  <img src={  require(`../../assets/images/dummy/playlist-icon.png`)} alt="media" width='60px'  style={{ userSelect:'none', borderRadius:"6px" }}   />
      }
    </MediaIconWrapper>
  )
}

const ScreenInfo = ({mediaName, type,duration,view, name, playListMediaCount}) => {
  return (
  <InfoWrapper padding='0px'  width='60%'>  
    <Title color={secondary_color} size='14px'> {  
    name ? name.substring(0, 18) :  (mediaName && mediaName.substring(0, 20).split('-').join(" ")) 
    }  </Title>
    <FlexWrapper align='center' justify='center' paddingTop="6px"> 
    <Subtitle marginRight='20px' color={secondary_color}> {type} </Subtitle>
    {
        type === 'playlist' ?<Subtitle marginRight='20px' color={secondary_color}>  {playListMediaCount} Items </Subtitle>: 
        <Subtitle marginRight='20px' color={secondary_color}>  {duration} Sec </Subtitle>
      }  

    <Subtitle color={secondary_color}>  {view}  </Subtitle>
    </FlexWrapper>
  </InfoWrapper>

  )
}

const index = ({item, handleMediaRemove, provided, playlist, handleSettings}) => {
  const { name, mediaName, type,duration,view,status, url, thumbnail, mediaCount, id } = item
  const currentPlaylist = (type === 'playlist' && id &&  playlist &&  playlist.find((item) => item.id === id )) || {}
  const playListMediaCount = currentPlaylist.mediaCount || mediaCount
  return (
    <MediaListCard width="540px"
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    > 
      <MediaIcon type={type} url={url} thumbnail={thumbnail} mediaName={mediaName} ></MediaIcon>
      <ScreenInfo 
       {...{
        mediaName, type,duration,view,status, name, playListMediaCount
       }}
      ></ScreenInfo>
      <FlexWrapper width="20%" justify='center' >
      {
          type === 'Image' ? <SecondaryButton hovercolor={primary_color} color={secondary_color} onClick={() => handleSettings(item)}   > 
          <i className="fa-solid fa-gear"></i>
          </SecondaryButton> : <SecondaryButton hovercolor={'#fff'} color={'#fff'}    > 
          <i className="fa-solid fa-gear"></i>
          </SecondaryButton> 
      }  
      <SecondaryButton color={secondary_color} hovercolor={red_color}  onClick={ () => handleMediaRemove(item)}>
          <i className="fa-solid fa-trash-can"></i>
      </SecondaryButton>
      </FlexWrapper>
    </MediaListCard>
  )
}

export default index