import styled from 'styled-components'

export const AppStyle = styled.div`
display: flex;
background-color: #282c34;
height: 100vh;
`


export const LogoWrapper = styled.div`
display: flex;
background-color: ${props => props.bgColor};
padding: 8px 40px;
display:flex;
align-items:center;
margin-top:30px
`

export const LogoText = styled.p`
font-size: 14px;
font-family: 'Roboto', sans-serif;
font-weight: 500;
letter-spacing:1px;
margin: 0;
align-items: center;
margin-left: 10px
`


