import styled from 'styled-components'
import { primary_color } from '../../const/color_theam'


export const LinkText = styled.p`
color: ${primary_color};
font-size: 12px;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
margin: 0;
align-items: center;
cursor: pointer;
`