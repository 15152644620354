import moment from 'moment'


export const dateToFromNowDaily = (myDate) => {
  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    sameDay: '[Today at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: '[Last] dddd',
    sameElse: function(now) {
      if (now.diff(myDate, 'hours') < 24) {
        return '[Today at] LT';
      } else {
        return 'MMM D, YYYY';
      }
    }
  });
}


export const dateToFromNowDailyWithOutTime = (myDate) => {
  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: function(now) {
      if (now.diff(myDate, 'hours') < 24) {
        return '[Today]';
      } else {
        return 'MMM D, YYYY';
      }
    }
  });
}