import React from 'react'
import { useState } from 'react'
import { light_color, primary_color } from '../../const/color_theam'
import { SmallButton } from '../button/Button.style'
import { Caption } from '../typography/Caption.style'
import { FlexWrapper } from '../wrapper/Wrapper.style'
import { StlyedText, RoundButton, ColorGridWrapper, SelectButton, IconButton } from './scroll.style'


const Index = ({ handleScroll, handleScrollText, setScrollBg, setScrollFont, scrollText, scrollBg, scrollFont, }) => {
  const [colorPalette, setColorPalette] = useState(null)
  
  const onBgSelect = (color) => {
    setScrollBg(color)
    setColorPalette(null)
  }

  const onFontelect = (color) => {
    setScrollFont(color)
    setColorPalette(null)
  }

  return (
    <>
      <Caption color={light_color}> Scroll Settings </Caption>
      <StlyedText color={scrollFont} bgColor={scrollBg} onChange={handleScrollText} value={scrollText} ></StlyedText>

      <FlexWrapper >

        <FlexWrapper width="46%" direction="row" align='center'>
          <Caption color={light_color}> Background Color</Caption>
          <SelectButton bgColor={scrollBg}  >
          <IconButton onClick={() => setColorPalette('bgColor')  }></IconButton>

            {
             colorPalette &&  colorPalette === 'bgColor' &&
              <ColorGridWrapper>
                <RoundButton bgColor="#F5F5F5" onClick={() => onBgSelect("#F5F5F5")} />
                <RoundButton bgColor="#424874" onClick={() => onBgSelect("#424874")} />
                <RoundButton bgColor="#777777" onClick={() => onBgSelect("#777777")} />
                <RoundButton bgColor="#00A6A6" onClick={() => onBgSelect("#00A6A6")} />
                <RoundButton bgColor="#008FD3" onClick={() => onBgSelect("#008FD3")} />
              </ColorGridWrapper>
            }


          </SelectButton>

        </FlexWrapper>

        <FlexWrapper width="38%" direction="row" align='center'>
          <Caption color={light_color}> Text color </Caption>

          <SelectButton bgColor={scrollFont}  >
            <IconButton onClick={() => setColorPalette('fontColor') }></IconButton>
            {
             colorPalette &&  colorPalette === 'fontColor' &&
              <ColorGridWrapper>
                <RoundButton bgColor="#060609" onClick={() => onFontelect("#060609")} />
                <RoundButton bgColor="#E6E6E6" onClick={() => onFontelect("#E6E6E6")} />
                <RoundButton bgColor="#FFC700" onClick={() => onFontelect("#FFC700")} />
                <RoundButton bgColor="#FF5757" onClick={() => onFontelect("#FF5757")} />
                <RoundButton bgColor="#1D7874" onClick={() => onFontelect("#1D7874")} />
              </ColorGridWrapper>
            }
          </SelectButton>


        </FlexWrapper>

        <FlexWrapper justify="center" align="flex-end" width="18%"  >
          <SmallButton color={primary_color} active onClick={() => handleScroll(true)} > Add </SmallButton>
        </FlexWrapper>

      </FlexWrapper>
    </>
  )
}

export default Index