import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { primary_color, secondary_color, light_background } from '../../const/color_theam';
import { playlist, media, screens } from '../../const/path';
import { Navbar } from '../header/Navbar.style'
import { NavbarButton } from '../header/NavbarButton.style'
import { StyledLink } from '../Link/StyledLink.style';
// import { NotificationWrapper } from '../wrapper/Wrapper.style';
import {LogoText, LogoWrapper } from './Menu.style';
// import Notification from '../Notification/index'
// import UserInfo from '../UserInfo/index'
import { UserButton, ButtonWrapper } from '../button/Button.style';
import { Messege } from '../typography/Caption.style';
import { getUserName } from '../../utils/commonUtils';
import { FlexWrapper } from '../wrapper/Wrapper.style';


const Index = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path)

  const navigate = useNavigate();
  const logout = () => {
      localStorage.clear();
      navigate('/');
  }

  return (
    <div>
      <LogoWrapper bgColor={light_background}>
      <img src={require(`../../assets/logo/viewbox_new.png`)} alt="viewbox" width="30px" height="30px"  ></img>
      <LogoText> ViewBox </LogoText> 
      </LogoWrapper>
      <Navbar>
        <NavbarButton isActive={isActive(screens)}>
          <i className="fa-solid fa-tv" style={{  fontSize: '14px' ,  marginRight: '12px', color: isActive(screens) ? primary_color : secondary_color }} ></i>
          <StyledLink isActive={isActive(screens)} to={screens}>Screen</StyledLink>
        </NavbarButton>

        <NavbarButton isActive={isActive(media)}>
          <i className="fa-solid fa-photo-film" style={{  fontSize: '14px' ,  marginRight: '12px', color: isActive(media) ? primary_color : secondary_color }} ></i>
          <StyledLink isActive={isActive(media)} to={media}>Media</StyledLink>
        </NavbarButton>
        <NavbarButton isActive={isActive(playlist)}>
          <i className="fa-solid fa-rectangle-list" style={{ fontSize: '14px' ,  marginRight: '12px', color: isActive(playlist) ? primary_color : secondary_color }} ></i>
          <StyledLink isActive={isActive(playlist)} to={playlist}>Playlist</StyledLink>
        </NavbarButton>

        {/* <NavbarButton isActive={isActive(scheduler)}>
          <i className="fa-solid fa-calendar-days" style={{ fontSize: '14px' ,  marginRight: '12px', color: isActive(scheduler) ? primary_color : secondary_color }} ></i>
          <StyledLink isActive={isActive(scheduler)} to={scheduler}>Scheduler</StyledLink>
        </NavbarButton> */}

        {/* <NavbarButton isActive={isActive(apps)}>
          <i className="fa-solid fa-table-cells" style={{ fontSize: '14px' ,  marginRight: '12px', color: isActive(apps) ? primary_color : secondary_color }} ></i>
          <StyledLink isActive={isActive(apps)} to={apps}>Apps</StyledLink>
        </NavbarButton> */}
{/* 
        <NavbarButton isActive={isActive('Settings')}>
          <i className="fa-solid fa-sliders" style={{ fontSize: '14px' ,  marginRight: '12px', color: isActive('Settings') ? primary_color : secondary_color }} ></i>
          <StyledLink isActive={isActive('Settings')} to={'Settings'}>Settings</StyledLink>
        </NavbarButton> */}
      </Navbar>

{/* 
      <NotificationWrapper>
        {
          info === 'userinfo' && <UserInfo closeUserInfo={closeUserInfo} /> 
        }
        {
          info === 'notification' &&  <Notification />
        }
      </NotificationWrapper> */}
      {/* <UserButton color={primary_color} onClick={(e)=> openUserInfo(e) } ><i class="fa-solid fa-user"></i></UserButton> */}


    <ButtonWrapper>  
      <FlexWrapper align='center' marginBottom='10px'>
      <UserButton color={primary_color} ><i class="fa-solid fa-user"></i></UserButton> 
      <Messege  margin='16px' color={secondary_color} >  {getUserName().charAt(0).toUpperCase() + getUserName().slice(1)} </Messege>
      </FlexWrapper>
      <FlexWrapper align='center' onClick={(e) => logout(e) }>
      <UserButton  color={primary_color} ><i class="fa-solid fa-right-from-bracket"></i></UserButton> 
      <Messege  margin='16px' color={secondary_color} >  Logout </Messege>
      </FlexWrapper>
    </ButtonWrapper>

    </div>
  )
}

export default Index