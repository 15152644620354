import React from 'react'
import {  theam_black } from '../../const/color_theam'
import { Label } from '../typography/Label.style'
import { Input } from './Input.style'


const index = ({label , value, handleInputChange, type="text", name, border = false , color, placeholder='' }) => {
  return (
    <>
   <Label color={ color || theam_black} > {label} </Label>
   <Input border={border} type={type} value={ value } name={name} onChange={ handleInputChange } placeholder={placeholder}  />
    </>
  )
}

export default index