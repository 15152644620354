
import styled from 'styled-components'
import { primary_color } from '../../const/color_theam'


export const StlyedText = styled.textarea`
height: 110px;
width: 400px;
background-color: green;
border-radius: 6px;
padding: 14px;
overflow-y: scroll;
background-color: ${ (props) => props.bgColor || '' };
border: none;
margin-top: 20px;
margin-bottom: 20px;
color: ${ (props) => props.color || '' };
font-size: 16px;

::-webkit-scrollbar {
    display: none;
}

&:focus{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    outline: none;

}`

export const RoundButton = styled.div`
height: 24px;
width: 24px;
background-color: ${ (props) => props.bgColor || '' };
border-radius: 4px;
/* padding: 10px; */
/* border:  ${ (props) => props.active ? `2px solid ${primary_color}` : ""  }; */
margin-top: 20px;
cursor: pointer;

&:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
`
export const GridWrapper = styled.div`
  display: grid;
  grid-row-gap: 0px;
  grid-column-gap: 0px;
  grid-template-columns: repeat(2, 1fr);
  width: 100px;
`

export const SelectButton = styled.div`
 width: 30px;
 height: 30px;
 background-color: ${props => props.bgColor};
 margin-left: 20px;
 position: relative;
 border-radius: 4px;
`

export const ColorGridWrapper = styled.div`
display: grid;
grid-row-gap: 0px;
grid-column-gap: 0px;
grid-template-columns: repeat(5, 1fr);
width: 100px;
position: absolute;
bottom: -34px;
left: -34px;
`
export const IconButton = styled.div`
width: 30px;
height: 30px;
background-color: transparent;
position: absolute;
top: 0px;
left: 0px;
display: flex;
justify-content: center;
align-items: center;
`
