export const updateScreenList = (screens, dispatch) => {
  dispatch({
      type: 'UPDATE_SCREEN_LIST',
      data: screens,
  })
};

export const setSubacriptionWarnning = (value, dispatch) => {
  dispatch({
      type: 'SET_SUBSCRIPTION_WARNNING',
      data: value,
  })
};

export const addScreen = (screen, dispatch) => {
  dispatch({
      type: 'ADD_SCREEN',
      data: screen,
  })
};

export const removeScreen = (name, dispatch) => {
  dispatch({
      type: 'REMOVE_SCREEN',
      name: name,
  })
};
