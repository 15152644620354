import styled from 'styled-components'
import { primary_color } from '../../const/color_theam'

export const NavbarButton = styled.button`
border: none;
border-right: 4px solid;
border-color: ${ (props) => props.isActive ? primary_color  : 'transparent' };
font-family: 'Roboto', sans-serif;
text-decoration: none;
padding: 8px 40px;
font-size: ${ (props) => props.isActive ? '12px'  : '12px' };
font-weight: ${ (props) => props.isActive ? 400  : 400 };
margin: 0;
width: 100%;
background-color: transparent;
margin-bottom:18px;
text-align: left;
letter-spacing: 1px;
`
export const NavbarButtonSmall = styled.button`
border: none;
border-bottom: 3px solid;
border-color: ${ (props) => props.color || '#C1C2C2' };
color: ${ (props) => props.color || '#C1C2C2' };
font-family: 'Roboto', sans-serif;
text-decoration: none;
padding: 12px 28px;
font-size: 14px;
font-weight: ${ (props) => props.color ? 500 : 400 };
margin: 0;
background-color: transparent;
cursor: pointer;
`
